import { 
    getGridStringOperators,
    GridFilterOperator,
    GridFilterItem,
    GridFilterInputValue 
} from '@mui/x-data-grid-pro'

import { IntlShape } from "react-intl";

export const getExtendedStringFilterOptions: (intl: IntlShape) => GridFilterOperator[] = (intl) => [
    ...getGridStringOperators(),
    {
      value: 'doesNotEqual',
      label: intl.formatMessage({id: 'filters.doesNotEqual'}),
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
          return null;
        }
        return ({ value }): boolean => {
          return (
            filterItem.value?.localeCompare((value && value.toString()) || '', undefined, {
              sensitivity: 'base',
            }) !== 0
          );
        };
      },
      InputComponent: GridFilterInputValue,
    },
    {
      value: 'doesNotContain',
      label: intl.formatMessage({id: 'filters.doesNotContain'}),
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
          return null;
        }
  
        const filterRegex = new RegExp(filterItem.value, 'i');
        return ({ value }): boolean => {
          return !filterRegex.test((value && value.toString()) || '');
        };
      },
      InputComponent: GridFilterInputValue,
    },
    {
      value: 'doesNotStartWith',
      label: intl.formatMessage({id: 'filters.doesNotStartWith'}),
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
          return null;
        }
  
        const filterRegex = new RegExp(`^${filterItem.value}.*$`, 'i');
        return ({ value }): boolean => {
          return !filterRegex.test((value && value.toString()) || '');
        };
      },
      InputComponent: GridFilterInputValue,
    },
    {
      value: 'doesNotEndWith',
      label: intl.formatMessage({id: 'filters.doesNotEndWith'}),
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
          return null;
        }
  
        const filterRegex = new RegExp(`.*${filterItem.value}$`, 'i');
        return ({ value }): boolean => {
          return !filterRegex.test((value && value.toString()) || '');
        };
      },
      InputComponent: GridFilterInputValue,
    },
  ]
