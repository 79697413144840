import { createTheme } from '@mui/material/styles';
import { enUS } from '@mui/x-data-grid-pro'

export const catapultTheme = createTheme({
  palette: {
    primary: {
      light: '#ff833c',
      main: '#e35205',
      dark: '#c44100'
    },
    secondary: {
      light: '#808587',
      main: '#54585A',
      dark: '#343a40'
    }
  },
  typography: {
    h3: {
      fontSize: '2rem',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'sans-serif'
      ].join(','),
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      textTransform: 'uppercase'
    }
  },
}, enUS)
