import { Theme } from '@mui/material'
import { buildChartTheme } from '@visx/xychart'

export const getXYChartTheme = (theme: Theme) => {
  return buildChartTheme({
    colors: [theme.palette.primary.light], // categorical colors, mapped to series via `dataKey`s
    gridColor: theme.palette.secondary.light,
    gridColorDark: theme.palette.secondary.main,
    backgroundColor: 'white',
    tickLength: 7
  })
}
