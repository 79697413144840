import {
  Grid,
  Container,
  Typography,
} from '@mui/material'
import axios from 'axios'
import React, { CSSProperties, FormEvent, useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ConfigField, getInputTypeWidth } from './ConfigField'
import { SaveButton } from './SaveButton'
import { PageLoadingIndicator } from './PageLoadingIndicator'
import { useSnackbar } from "notistack"
import { usePageStatus, PageLoadingStatus } from "./usePageStatus"

interface ConfigFieldData {
  key: string
  value: String | Number | string[]
  readOnly: Number | boolean
  category?: string
  inputType?: string
  options?: string[]
  defaultOptionsText?: string[]
}

interface GroupingData {
  groupName?: string
  settingName?: string
  grouping?: GroupingData[]
  settingData?: ConfigFieldData
}

interface GroupedConfigFieldData {
  [key: string]: ConfigFieldData[]
}


const uncategorized = 'uncategorized'

/**
 *
 * @returns a functional component responsible for rendering nodes in the configuration tree.
 * Can represent either a category or a setting/config.
 */
function ConfigNode({
  groupingData,
  configFields,
  onChange,
  savePassword,
  style,
  isRoot = false,
}: {
  groupingData: GroupingData
  configFields: ConfigFieldData[]
  onChange: Function
  savePassword?(fieldKey: string, password: string): Promise<void>
  isRoot?: boolean
  style?: CSSProperties
}) {
  let configIndex
  const configFieldData = configFields.find((configField, index) => {
    if (configField.key === groupingData.settingName) {
      configIndex = index
      return true
    }
    return false
  })

  return (
    <React.Fragment>
    {groupingData.groupName ? (
      <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h6' sx={{ mt: 2 }}>
              {groupingData.groupName}
            </Typography>
          </Grid>
          {groupingData.grouping && (
          <React.Fragment>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <Grid container spacing={2}>
              {groupingData.grouping &&
                groupingData.grouping.map((node: GroupingData) => (
                  <ConfigNode
                    groupingData={node}
                    configFields={configFields}
                    onChange={onChange}
                    savePassword={savePassword}
                    style={{ marginBottom: node.groupName ? 3 : 0 }}
                  />
              ))}            
              </Grid>
            </Grid>
          </React.Fragment>
          )}
        </Grid>
      )
      :
        <Grid item xs={12} sm={6} md={getInputTypeWidth(configFieldData?.inputType)}>
        {configFieldData && (
          <ConfigField
            i18nNameKey={configFieldData.key.toString()}
            initialValue={configFieldData.value}
            configIndex={configIndex || 0}
            isReadOnly={
              typeof configFieldData.readOnly === 'boolean'
                ? configFieldData.readOnly
                : configFieldData.readOnly > 0
            }
            category={configFieldData.category || uncategorized}
            onChange={onChange}
            inputType={configFieldData.inputType}
            selectOptions={configFieldData.options}
            savePassword={
              configFieldData.inputType === 'password' ? savePassword : undefined
            }
            defaultOptionsText={configFieldData.defaultOptionsText}
          />
          )}
        </Grid>
      }
      </React.Fragment>
  )
}

export function ConfigPage() {
  const intl = useIntl()
  const { enqueueSnackbar } = useSnackbar();

  const [configFieldData, setConfigFieldData] = useState(
    {} as GroupedConfigFieldData
  )
  const [configList, setConfigList] = useState<ConfigFieldData[]>([])
  const [groupingData, setGroupingData] = useState([] as GroupingData[])
  const [saving, setSaving] = useState(false)
  const { setStatus, pageLoaded, pageLoading, pageError } = usePageStatus();//useState<PageLoadingStatus>('init')
  const [currentUserString] = useState(localStorage.getItem('currentUser'))
  const [currentUser] = useState(
    currentUserString ? JSON.parse(currentUserString) : {}
  )

  const loadConfigData = useCallback(() => {
    setStatus(PageLoadingStatus.LOADING);

    let configurationList: ConfigFieldData[] = []
    let grouping: GroupingData[] = []
    axios
      .get(`/config/grouping`, {
        headers: {
          Authorization: `Bearer ${currentUser.token}`
        }
      })
      .then((res) => {
        grouping = res.data.grouping
        setGroupingData(grouping)
        axios
          .get(`/config/list`, {
            headers: {
              Authorization: `Bearer ${currentUser.token}`
            }
          })
          .then((response) => {
            configurationList = response.data.configList
            setConfigList(configurationList)
            let output: GroupedConfigFieldData = {}
            configurationList.reduce((r, a) => {
              let categoryKey = a.category || uncategorized
              r[categoryKey] = r[categoryKey] || []
              r[categoryKey].push(a)
              return r
            }, output)
            setConfigFieldData(output)
            setStatus(PageLoadingStatus.LOADED);
          })
          .catch(() => {
            enqueueSnackbar(intl.formatMessage({ id: "config.problemLoadingConfig"}));
            setStatus(PageLoadingStatus.ERROR);
          })
      })
      .catch(() => {
        enqueueSnackbar(intl.formatMessage({ id: "config.problemLoadingConfig"}));
        setStatus(PageLoadingStatus.ERROR);
      })
  }, [currentUser.token])

  const saveConfigData = (e: FormEvent) => {
    e.preventDefault()
    setSaving(true)
    let filteredConfigFieldData: GroupedConfigFieldData = {} // holds config field data except for passwords
    for (let group in configFieldData) {
      filteredConfigFieldData[group] = configFieldData[group].filter(
        (config) => {
          return config.inputType !== 'password'
        }
      )
    }
    const queryParameters = Object.fromEntries(
      ([] as ConfigFieldData[])
        .concat(...Object.values(filteredConfigFieldData))
        .map(
          (configData: {
            key: String
            value: String | Number | string[]
            readOnly: Number | Boolean
            category?: String
          }) => {
            return [configData.key, configData.value]
          }
        )
    )

    axios
      .post('/config/setValue', null, {
        params: queryParameters,
        headers: {
          Authorization: `Bearer ${currentUser.token}`
        }
      })
      .then(() => {
        setSaving(false)
        enqueueSnackbar(intl.formatMessage({ id: "config.configSaved"}));
        loadConfigData()
      })
      .catch(() => {
        enqueueSnackbar(intl.formatMessage({ id: "config.problemSavingConfig"}));
      })
  }

  const savePassword = (configKey: string, configPass: string) => {
    return axios
      .post('/config/setValue', null, {
        params: { [configKey]: configPass },
        headers: {
          Authorization: `Bearer ${currentUser.token}`
        }
      })
      .then(() => {
        setSaving(false)
        enqueueSnackbar(intl.formatMessage({ id: "config.configSaved"}));
        loadConfigData()
      })
      .catch(() => {
        setSaving(false)
        enqueueSnackbar(intl.formatMessage({ id: "config.problemSavingConfigPassword"}));
        loadConfigData()
      })
  }

  const updateStateForField = (
    i18nNameKey: string,
    index: number,
    value: String | Number | string[],
    category: string
  ) => {
    let groupedConfigFieldDataCopy = Object.assign({}, configFieldData)
    let groupDataArrayCopy = [...configFieldData[category]]
    let configIndex = -1
    groupDataArrayCopy.find((configField, index) => {
      if (configField.key === i18nNameKey) {
        configIndex = index
        return true
      }
      return false
    })
    let fieldDataCopy = {
      ...groupDataArrayCopy[configIndex],
      value: value
    }
    groupDataArrayCopy[configIndex] = fieldDataCopy
    groupedConfigFieldDataCopy[category] = groupDataArrayCopy
    setConfigFieldData(groupedConfigFieldDataCopy)
  }


  useEffect(() => {
    loadConfigData()
  }, [loadConfigData])
  return (
    <Container sx={{ width: '100%' }}>
      {pageLoading() && <PageLoadingIndicator />}
      {pageLoaded() && (
        <form id='config-form' onSubmit={saveConfigData}>
          <Typography variant={'h3'} style={{ padding: '0.5em 0 0.5em 0' }}>
            {intl.formatMessage({ id: 'config' })}
          </Typography>
          {configList.length < 1 && pageError() && (
            <Typography>
              <FormattedMessage id='config.unableToLoadConfig' />
            </Typography>
          )}
          {configList.length < 1 && pageLoaded() && (
            <Typography>
              <FormattedMessage id='config.noConfigs' />
            </Typography>
          )}
          {
            // working from first level instead of actual root node,
            // to prevent indentation on first level
            groupingData &&
              groupingData.map((group: GroupingData) => (
                <ConfigNode
                  groupingData={group}
                  configFields={configList}
                  onChange={updateStateForField}
                  savePassword={savePassword}
                  isRoot={true}
                  style={{ marginBottom: 3 }}
                />
              ))
          }
          {configList.length > 0 &&
            !pageError() && (
              <SaveButton
                saving={saving}
                formId='config-form'
                isFormSubmit={true}
              />
            )}
        </form>
      )}

    </Container>
  )
}
