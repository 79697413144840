import { useTheme } from '@mui/material'
import { Group } from '@visx/group'
import { scaleOrdinal } from '@visx/scale'
import Pie, { PieArcDatum, ProvidedProps } from '@visx/shape/lib/shapes/Pie'
import React from 'react'
import { useIntl } from 'react-intl'
import { animated, interpolate, useTransition } from 'react-spring'

const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 }

export interface SingleValueDonutChartProps {
  metric: number
  width: number
  height: number
  margin?: typeof defaultMargin
  colorRule: (value: number) => string
  animate?: boolean
}

export interface SingleValueDonutDatum {
  label: 'value' | 'complement'
  percentage: number
}

// accessor functions
const percentage = (d: SingleValueDonutDatum) => d.percentage

export function SingleValueDonutChart({
  width,
  height,
  metric,
  margin = defaultMargin,
  colorRule,
  animate = false,
}: SingleValueDonutChartProps) {
  const getArcColor = scaleOrdinal({
    domain: ['value', 'complement'],
    range: [colorRule(metric), '#b6c3cc']
  })

  const data: SingleValueDonutDatum[] = [
    { label: 'value', percentage: metric },
    { label: 'complement', percentage: 100 - metric }
  ]

  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom
  const radius = Math.min(innerWidth, innerHeight) / 2
  const centerY = innerHeight / 2
  const centerX = innerWidth / 2
  const donutThickness = 10

  return (
    <svg width={width} height={height}>
      <rect width={width} height={height} fillOpacity={0} />
      <Group top={centerY + margin.top} left={centerX + margin.left}>
        <Pie
          top={centerY + margin.top}
          left={centerX + margin.left}
          data={data}
          pieValue={percentage}
          outerRadius={radius}
          innerRadius={radius - donutThickness}
          padAngle={0.005}
        >
          {(pie) => (
            <AnimatedPie<SingleValueDonutDatum>
              animate={animate}
              {...pie}
              getKey={(arc) => arc.data.label}
              getColor={(arc) => getArcColor(arc.data.label)}
              top={centerY + margin.top}
              left={centerX + margin.left}
            />
          )}
        </Pie>
      </Group>
    </svg>
  )
}

// react-spring transition definitions
type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number }

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0
})
const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1
})

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<Datum>) => string;
  getColor: (d: PieArcDatum<Datum>) => string;
  delay?: number;
  top: number;
  left: number;
}

function AnimatedPie<Datum>({
  animate,
  arcs,
  path,
  getKey,
  getColor
}: AnimatedPieProps<Datum>) {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(
    arcs,
    // @ts-ignore react-spring doesn't like this overload
    {
      from: animate ? fromLeaveTransition : enterUpdateTransition,
      enter: enterUpdateTransition,
      update: enterUpdateTransition,
      leave: animate ? fromLeaveTransition : enterUpdateTransition,
      keys: getKey,
    }
  );

  const intl = useIntl()
  const theme = useTheme()
  return (
    <Group>
      {arcs[0].data && (
        <text textAnchor='middle'>
          <tspan
            x='0'
            dy='0.3em'
            fontSize='1.5em'
            fontFamily={theme.typography.fontFamily}
          >
            {intl.formatNumber(
              Math.round(
                percentage((arcs[0].data as unknown) as SingleValueDonutDatum)
              ) / 100, {style: 'percent'}
            )}
          </tspan>
        </text>
      )}
      {
//      transitions.map(
//        ({
//          item: arc,
//          props,
//          key
//        }: {
//          item: PieArcDatum<Datum>
//          props: AnimatedStyles
//          key: string
//        }) => {
        transitions((props, arc, { key }) => {
          return (
            <g key={key}>
              <animated.path
                // compute interpolated path d attribute from intermediate angle values
                d={interpolate(
                  [props.startAngle, props.endAngle],
                  (startAngle, endAngle) =>
                    path({
                      ...arc,
                      //make angles negative to render donut segments clockwise
                      startAngle: -1 * startAngle,
                      endAngle: -1 * endAngle
                    })
                )}
                fill={getColor(arc)}
              />
            </g>
          )
        }
      )}
    </Group>
  )
}
