import { CircularProgress } from '@mui/material';
import React from 'react';

export function PageLoadingIndicator() {
  return <CircularProgress
          size={64}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -32,
            marginLeft: -32,
          }}
        />
}
