import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom'

import AppRouter from "./AppRouter";

// Theme and styling
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { catapultTheme } from "catapult-shared-ui-components";

import Box from "@mui/material/Box"

import { LicenseInfo } from '@mui/x-license-pro';
import { ProvideAuth } from "./hooks/useAuth"
import { useIntl } from 'react-intl';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import enNZ from "dayjs/locale/en-nz"
import en from "dayjs/locale/en"
import { SnackbarProvider } from 'notistack';

const daysJs = {
  "en-NZ": enNZ
}

LicenseInfo.setLicenseKey(
    "f27e07bccc26cbf85c2a738738d9fbb4Tz00ODUxNixFPTE2OTEyMjYxNjc0NTYsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
  );
  

  const theme = createTheme(catapultTheme, {
    typography: {
      fontSize: 14,
    },
    components: {
      MuiTooltip: {
        styleOverrides:{
          tooltip: {
            fontSize: '1em',
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: {
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            width: "0.4em",
            height: "0.4em",
          },
          '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            // need to specify Catapult theme instead of "provided" theme because @global selector falls outside theme provider.
            backgroundColor: catapultTheme.palette.primary.main,
            borderRadius: "0.2rem",
          },

        }
      }
    }
  });

function App() {

  const intl = useIntl();

  return (
    <ProvideAuth>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={daysJs[intl.locale] || en}>  
          <Router>
            <Box sx={{
                  height: "100vh", 
                  maxheight: "100vh",
                  display: "flex",
                  flexDirection: "column",                
                }}>
              <CssBaseline />
              <SnackbarProvider 
                maxSnack={3}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                sx={{
                  '& .SnackbarItem-variantWarning': {
                    backgroundColor: "#123456",
                  },
                }}              
              >
                  <AppRouter />
              </SnackbarProvider>
            </Box>
          </Router>
        </LocalizationProvider>
      </ThemeProvider>
    </ProvideAuth>
  );
}
  
export default App;
  