import React, { useEffect, useMemo, useRef, useState } from 'react'
import { XYChart, LineSeries, Axis as XYChartAxis, Grid, Tooltip } from '@visx/xychart'
import { Axis, SharedAxisProps, AxisScale } from '@visx/axis';
import { Brush } from '@visx/brush'
import { Bounds } from '@visx/brush/lib/types'
import BaseBrush from '@visx/brush/lib/BaseBrush'
import { Typography, useTheme } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { scaleLinear, scaleTime } from '@visx/scale'
import { max, extent } from 'd3-array'
import { PatternLines } from '@visx/pattern'
import { LinePath } from '@visx/shape'

export interface TimeSeriesDatum {
  x: Date
  y: number
  seriesKey: string
}

export interface TimeSeriesChartProps {
  width: number
  height: number
  data: TimeSeriesDatum[]
  variableNameKey: string
  useGranularTooltip?: boolean
  showBrush?: boolean
}

type AxisComponent = React.FC<
  SharedAxisProps<AxisScale>>;

export function TimeSeriesChart({
  width,
  height,
  data,
  variableNameKey,
  useGranularTooltip = false,
  showBrush = true,
}: TimeSeriesChartProps) {
  const intl = useIntl()
  const theme = useTheme()
  const AxisComponent: AxisComponent = Axis;
  const accessors = {
    xAccessor: (d: TimeSeriesDatum) => d?.x,
    yAccessor: (d: TimeSeriesDatum) => d?.y
  }
  const brushMargin = { top: 10, bottom: 15, left: 50, right: 20 }
  const chartSeparation = 30
  const margin = {
    top: 20,
    left: 50,
    bottom: 20,
    right: 20
  }
  const brushRef = useRef<BaseBrush | null>(null)
  const [filteredData, setFilteredData] = useState(data)
  const PATTERN_ID = 'brush_pattern'
  const selectedBrushStyle = {
    fill: `url(#${PATTERN_ID})`,
    stroke: 'black'
  }
  const strokeWidth = 2;

  useEffect(() => setFilteredData(data), [data])

  // Split line into segments, at the points where there is an undefined point (no data).
  // This is used so that the LinePath in the Brush component doesn't join the line across the data gaps.
   const hasBreaksInLine = data.find((d) => accessors.yAccessor(d) === undefined) !== undefined;
   var lineSections = [] as TimeSeriesDatum[][]
   if (hasBreaksInLine) {
    const breakIndices: number[] = data
      .map((d, i) => [d, i])
      .filter(
        (pair) => accessors.yAccessor(pair[0] as TimeSeriesDatum) === undefined
      )
      .map((pair) => pair[1] as number)
    for (var i = 0; i <= breakIndices.length; i++) {
      if (i === 0) {
        lineSections.push(data.slice(0, breakIndices[i]))
      } else if (i === breakIndices.length) {
        lineSections.push(data.slice(breakIndices[i-1] + 1, breakIndices[breakIndices.length]))
      }
      else {
        lineSections.push(data.slice(breakIndices[i-1] + 1, breakIndices[i]))
      }
    }
   } else {
    lineSections.push(data)
   }

  const onBrushChange = (domain: Bounds | null) => {
    if (!domain) return
    const { x0, x1, y0, y1 } = domain
    const dataCopy = data.filter((s) => {
      const x = accessors.xAccessor(s)
      const y = accessors.yAccessor(s)
      return (
        ((x as unknown) as number) > x0 &&
        ((x as unknown) as number) < x1 &&
        ((y > y0 &&
        y < y1) || y === undefined)
      )
    })
    setFilteredData(dataCopy)
  }

  const innerHeight = height - margin.top - margin.bottom
  const topChartBottomMargin = chartSeparation / 2
  const topChartHeight = 0.8 * innerHeight - topChartBottomMargin
  const bottomChartHeight = innerHeight - topChartHeight - chartSeparation

  // bounds
  const xBrushMax = Math.max(width - 2 * brushMargin.left, 0)
  const yBrushMax = Math.max(
    bottomChartHeight - brushMargin.top - brushMargin.bottom,
    0
  )

  const dateScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xBrushMax],
        domain: extent(filteredData, accessors.xAccessor) as [Date, Date]
      }),
    [accessors.xAccessor, filteredData, xBrushMax]
  )
  const brushDateScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xBrushMax],
        domain: extent(data, accessors.xAccessor) as [Date, Date]
      }),
    [accessors.xAccessor, data, xBrushMax]
  )
  const brushCountScale = useMemo(
    () =>
      scaleLinear({
        range: [yBrushMax, 0],
        domain: [0, max(data, accessors.yAccessor) || 0],
        nice: true
      }),
    [accessors.yAccessor, data, yBrushMax]
  )

  const initialBrushPosition = useMemo(
    () => ({
      start: { x: brushDateScale(accessors.xAccessor(data[0])) },
      end: { x: brushDateScale(accessors.xAccessor(data[data.length - 1])) }
    }),
    [accessors, brushDateScale, data]
  )

  if (!data || data.length < 1) {
    return (
      <Typography
        align='center'
        style={{
          lineHeight: height + 'px',
          width: width + 'px',
          height: height
        }}
      >
        <FormattedMessage id={'chart.noData'} />
      </Typography>
    )
  }
  return (
    <div>
      <XYChart
        width={width}
        height={height - yBrushMax - 100}
        xScale={{ type: 'utc' }}
        yScale={{
          type: 'linear',
          domain: [
            0,
            Math.max(
              ...data
                .map((datum) => accessors.yAccessor(datum))
                .filter((yValue) => yValue || yValue === 0)
            )
          ]
        }}
      >
        <LineSeries
          dataKey={variableNameKey}
          data={filteredData}
          xAccessor={accessors.xAccessor}
          yAccessor={accessors.yAccessor}
        />
        <XYChartAxis
          orientation='left'
          label={intl.formatMessage({
            id: 'drilldown.' + variableNameKey + '.unit'
          })}
        />
        <AxisComponent
          orientation={'bottom'}
          top={innerHeight - brushMargin.top - yBrushMax - 100}
          left={brushMargin.left}
          scale={dateScale}
          label={intl.formatMessage({
            id: 'chart.date'
          })}
        />
        <Grid columns={false} />
        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          showVerticalCrosshair
          showSeriesGlyphs
          renderTooltip={({ tooltipData, colorScale }) => {
            const yValue = accessors.yAccessor(
              tooltipData?.nearestDatum?.datum as TimeSeriesDatum
            )
            const xValue = accessors.xAccessor(
              tooltipData?.nearestDatum?.datum as TimeSeriesDatum
            )
            if (yValue === undefined) {
              return (
                <div>
                  {intl.formatDate(xValue) + ': '}
                  <FormattedMessage id='chart.noDataForPoint' />
                </div>
              )
            } else {
              return (
                <div>
                  {intl.formatDate(xValue)}
                  {useGranularTooltip && ' '}
                  {useGranularTooltip && intl.formatTime(xValue)}
                  {': '}
                  <p
                    style={{
                      color: colorScale
                        ? colorScale(tooltipData?.nearestDatum?.key || '')
                        : 'rgb(0, 0, 0)',
                      display: 'inline'
                    }}
                  >
                    {' '}
                    <FormattedMessage
                      id={
                        'drilldown.' +
                        variableNameKey +
                        '.unit' +
                        (yValue > 1 || yValue === 0 ? '.plural' : '.singular')
                      }
                      values={{
                        value: yValue
                      }}
                    />
                  </p>
                </div>
              )
            }
          }}
        />
      </XYChart>
      {showBrush && (
        <svg height={yBrushMax + 100} width={width} y={height}>
          <g transform={`translate(${brushMargin.left}, ${brushMargin.top})`}>
            {lineSections.map((section, i) => (
              <LinePath
                stroke={theme.palette.primary.light}
                strokeWidth={2}
                data={section}
                x={(d) => brushDateScale(accessors.xAccessor(d)) ?? 0}
                y={(d) => brushCountScale(accessors.yAccessor(d)) ?? 0}
                height={yBrushMax}
                width={xBrushMax}
                key={i}
              />
            ))}
            <Brush
              xScale={brushDateScale}
              yScale={brushCountScale}
              width={xBrushMax}
              height={yBrushMax + strokeWidth}
              margin={brushMargin}
              handleSize={8}
              innerRef={brushRef}
              resizeTriggerAreas={['left', 'right']}
              brushDirection='horizontal'
              initialBrushPosition={initialBrushPosition}
              onChange={onBrushChange}
              onClick={() => setFilteredData(data)}
              selectedBoxStyle={selectedBrushStyle}
            />
            <PatternLines
              id={PATTERN_ID}
              height={8}
              width={8}
              stroke={theme.palette.grey[400]}
              strokeWidth={1}
              orientation={['diagonal']}
            />
            <AxisComponent
              orientation={'bottom'}
              top={yBrushMax + strokeWidth}
              scale={brushDateScale}
            />
          </g>
        </svg>
      )}
    </div>
  )
}
