import React, { useEffect, useState } from "react";
import { 
    Box,
    TextField,
    Typography
  } from "@mui/material";
  import {
    DataGridPro,
    GridColDef,
    GridToolbar,
  } from '@mui/x-data-grid-pro'


// hooks
import {useTheme}  from '@mui/material';
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack"
import {   
  FormattedMessage,
  useIntl,
} from "react-intl";

// components
import { LicenseInfo } from "../components/AppLicenseInfo";

// APi service
import {GetEvents, GetAlarmHealthFormats} from "../service/ApiService"

// Helpers
import latinize from "latinize";
import { FieldFactory } from "../helpers/GridFieldFactory";

interface AlarmListProps {
    licenseInfo?: LicenseInfo;
}
  
function AlarmList(props: AlarmListProps) {
    const theme = useTheme();
    const { dataset, range, tagName } = useParams() as {
      dataset: string;
      range: string;
      tagName: string;
    };
  
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const [rows, setRows] = useState([] as any[]);
    const [columns, setColumns] = useState([] as GridColDef[]);
    const [filter, setFilter] = useState<string>();

    const DefineColumns = (serverTz: string) => {
        let fields: GridColDef[];
        fields = [];
        fields.push(FieldFactory("timeLast", dataset, intl));
        fields.push(FieldFactory("source", dataset, intl));
        fields.push(FieldFactory("logicalNodeName", dataset, intl));
        fields.push(FieldFactory("tagName", dataset, intl, "", { size: 3 }));
        fields.push(FieldFactory("alarmText", dataset, intl));
        fields.push(FieldFactory("valueText", dataset, intl));
        fields.push(FieldFactory("areas", dataset, intl));
        fields.push(FieldFactory("extText1", dataset, intl));
        fields.push(FieldFactory("extText2", dataset, intl));
        fields.push(FieldFactory("operator", dataset, intl));
        fields.push(FieldFactory("priority", dataset, intl));
        fields.push(FieldFactory("state", dataset, intl));
        return fields;
    }

    // Initial loading of time series
    useEffect(() => {
        GetAlarmHealthFormats().then(formats => {
            const [rangeStart, rangeEnd] = range.split("..");
            let filter =
              "source=ALARM:state<>203:timeLast=" +
              rangeStart.replace(new RegExp(":", "g"), "\\:") +
              ".." +
              rangeEnd.replace(new RegExp(":", "g"), "\\:");
            if (
              dataset === "chattering_alarms" ||
              dataset === "fleeting_alarms" ||
              dataset === "most_frequent_alarms" ||
              dataset === "chattering_alarms_for_tag" ||
              dataset === "fleeting_alarms_for_tag"
            ) {
              if (tagName) {
                filter = filter.concat(":", "tagName=", tagName);
              }
            }
            GetEvents( "timeLast,source,logicalNodeName,nodeName,tagName,alarmText,valueText,areas,extText1,extText2,operator,priority,state", filter).
            then(result => {
                const fields = DefineColumns(formats?.data?.serverTz);
                setColumns(fields);            
                setRows(
                    result.data.data.map((row: any, i: any) => {
                      return { ...row, id: i, timeLast: new Date(row.timeLast) };
                    })
                  );
            })
            .catch(() => {
                enqueueSnackbar(intl.formatMessage({id: "dashboard.problemLoadingDashboard"}));
            })  
        })
        .catch(() => {
            enqueueSnackbar(intl.formatMessage({id: "dashboard.problemLoadingDashboard"}));
        })      
    }, [dataset]);

    
    return (
        <Box sx={{
            width: "100%",
            height: "100%",
            '& .MuiDataGrid-root .MuiDataGrid-row.Mui-odd:hover': {
                backgroundColor: 'revert',
            },
            }}
        >
        <Box
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            marginBottom: theme.spacing(2),
          }}
        >
          <Typography variant="h3" style={{ padding: "0.5em 0", flex: 0 }}>
            <FormattedMessage id={"drilldown.alarm_list.header"} />
          </Typography>
          <TextField
            variant="standard"
            type="search"
            placeholder={intl.formatMessage({ id: "search" })}
            onChange={(e) => {
              setFilter(latinize(e.target.value.toUpperCase()));
            }}
            style={{ marginBottom: theme.spacing(1) }}
          />          
          <DataGridPro
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                  outline: "none !important",
              },
            }} 
            components={{
              Toolbar: GridToolbar,
            }}
            rows={
              filter && filter !== ""
                ? rows.filter((row) =>
                  Object.getOwnPropertyNames(row).find((prop) =>
                    latinize(row[prop].toString().toUpperCase()).includes(
                    filter
                  )
                )
              )
              : rows
            }
            columns={columns}
            rowHeight={80}
            pagination={false}
            disableSelectionOnClick
            disableDensitySelector
            disableColumnReorder
            autoHeight
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        // in case of just one licensed SCADA, initially hide column "Node",
                        // the other columns will remain visible
                        logicalNodeName: props.licenseInfo?.scadaSystemsCount !== 1
                    }
                }
            }}
          />
        </Box>
    </Box>
  )
}

export default AlarmList;