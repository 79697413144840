import axios from 'axios';


const instance = axios.create({
    withCredentials: true,
    
})

export function GetNavItems() {

    const storage = JSON.parse(localStorage.getItem("currentUser") || "{}")
    const token = storage?.token;
    const get = instance.get(`/navItems`, token ? {
        headers: {
            Authorization: `Bearer ${token}`,
        }} : {});
    return get;
}

export function GetAlarmSummary(  ranges) {

    const storage = JSON.parse(localStorage.getItem("currentUser") || "{}")
    const token = storage ? storage.token : "";
   
    const get = instance.get(`/alarmHealth/summary`, {
        params: {
            ranges: ranges
          },        
          headers: {
            Authorization: `Bearer ${token}`,
        }});
    return get;
}

export function GetAlarmSummaryFields( period, previousPeriods, ranges) {

    const storage = JSON.parse(localStorage.getItem("currentUser") || "{}")
    const token = storage ? storage.token : "";
   
    const get = instance.get(`/alarmHealth/summaryFields`, {
        params: {
            period: period,
            previousPeriods: previousPeriods,
            ranges: ranges
          },        
          headers: {
            Authorization: `Bearer ${token}`,
        }});
    return get;
}

export function GetTimeSeriesData(dataset, range) {
 
    const storage = JSON.parse(localStorage.getItem("currentUser") || "{}")
    const token = storage ? storage.token : "";
   
    const get = instance.get(`/alarmHealth/timeSeriesData`, {
        params: {
            dataset: dataset,
            range: range
          },        
          headers: {
            Authorization: `Bearer ${token}`,
        }});
    return get;   
}

export function GetDrillDownData(dataset, range, tagName) {
 
    const storage = JSON.parse(localStorage.getItem("currentUser") || "{}")
    const token = storage ? storage.token : "";
    const params = {
        dataset: dataset,
        range: range
    }
    if (tagName && !tagName.startsWith("qty_")) {
        params.filter = "tagName=" + tagName;
    }
    const get = instance.get(`/alarmHealth/drillDownData`, {
        params: params,        
        headers: {
            Authorization: `Bearer ${token}`,
        }});
    return get;   
}

export function GetEvents(fields, filter) {
 
    const storage = JSON.parse(localStorage.getItem("currentUser") || "{}")
    const token = storage ? storage.token : "";
   
    const get = instance.get(`/getEvents`, {
        params: {
            fields: fields,
            filter: filter
          },        
          headers: {
            Authorization: `Bearer ${token}`,
        }});
    return get;   
}

export function GetAlarmHealthFormats() {

    const storage = JSON.parse(localStorage.getItem("currentUser") || "{}")
    const token = storage ? storage.token : "";
   
    const get = instance.get(`/alarmHealth/commonFormats`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }});
    return get;
}


export function SendResetPassword(userId, currentPassword, password) {

    const storage = JSON.parse(localStorage.getItem("currentUser") || "{}")
    const token = storage ? storage.token : "";
   
    const get = instance.post(`/user/${userId}/change_password`,
        {
            oldPassword: currentPassword,
            newPassword: password,
        }, 
        {
        headers: {
            Authorization: `Bearer ${token}`,
        }});
    return get;
}

export function SendLogin(userName, password) {

    const get = instance.post(`authenticate`,
        {
            username: userName,
            password: password,
        });
    return get;
}