import React, { useState } from "react";
import {useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ResetPasswordDialog from "./ResetPasswordDialog"

// hooks
import {useTheme}  from '@mui/material';
import {useAuth}  from '../hooks/useAuth';
import { FormattedMessage, useIntl } from "react-intl";

// icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**
 *  The component will render a button to allow and show the user login state
 * 
 *  If the user is not loged-in the button will be "Sign in"
 *  If the user is loged-in the button will be the user name and has a pulldown menu
 * 
 */

interface ThisUserMenuProps {
    children?: React.ReactNode;
}
  
function ThisUserMenu(props: ThisUserMenuProps) {
    const { children, ...other } = props;
    const theme = useTheme();
    const intl = useIntl();
    const auth = useAuth();
    const navigate = useNavigate();

    const [showPasswordChangeDialog, setShowPasswordChangeDialog] = useState(false);
    const [userMenu, SetUserMenu] = useState<null | HTMLElement>(null);
     
    const handleAccountClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (auth.HasAuthority(null))
            SetUserMenu(event.currentTarget);
        else
        {
            //login, no back history!
            navigate("/login", { replace: true });
        }
    };

    const logout = () => {
        SetUserMenu(null);
        auth.Logout();
      };
    
    const handleClose = () => {
        SetUserMenu(null);
        setShowPasswordChangeDialog(false);
      };


    const StartPasswordChange = () => {
        SetUserMenu(null);
        setShowPasswordChangeDialog(true)
    };


    const PasswordDialogClosed = (status: Boolean) => {
        SetUserMenu(null);
        setShowPasswordChangeDialog(false)
    };

    return (
        <>
         <ResetPasswordDialog
            userId={auth.GetUserId()}
            openChangePassword={showPasswordChangeDialog}
            onChangePassword={PasswordDialogClosed}
         />
         <Button
            color="secondary"
            sx={{
                flexGrow: 0,
                borderRadius: 0,
                padding: theme.spacing(2),
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
              }}
            aria-controls={auth.HasAuthority(null) ? "account-menu" : "sign-in"}
            aria-haspopup={auth.HasAuthority(null) != null}
            startIcon={<AccountCircleIcon />}
            endIcon={auth.HasAuthority(null) ? <ExpandMoreIcon /> : <></>}
            onClick={(event) => handleAccountClick(event)}
        >
            {auth.HasAuthority(null)
            ? auth.GetUsername()
            : intl.formatMessage({ id: "signIn" })}
        </Button>
        <Menu
            id="account-menu"
            anchorEl={userMenu}
            anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
            }}
            keepMounted
            open={Boolean(userMenu)}
            onClose={handleClose}
        >
            {auth.HasAuthority(null) && (
            <MenuItem onClick={() => StartPasswordChange()}>
                <FormattedMessage id="changePassword" />
            </MenuItem>
            )}
            <MenuItem onClick={logout}>
            <FormattedMessage id="logout" />
            </MenuItem>
        </Menu>             
      </>
    );
}
  
export default ThisUserMenu;
