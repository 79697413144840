import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useTheme
} from '@mui/material'
//Icons
import CloseIcon from '@mui/icons-material/Close';

import React from 'react'
import { ReactElement } from 'react'
import { useIntl } from 'react-intl'

export interface FormDialogProps {
  i18nTitleKey: string;
  open: boolean;
  openSetter: (value: boolean) => void;
  actionComponents: ReactElement[];
  children?: React.ReactNode;
}

export function FormDialog(props: FormDialogProps) {
  const intl = useIntl()
  const theme = useTheme()
  return (
    <Dialog open={props.open}>
      <DialogTitle>
        {intl.formatMessage({ id: props.i18nTitleKey })}
        <IconButton
          aria-label='close'
          onClick={() => props.openSetter(false)}
          style={{
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1)
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        style={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: '25em',
          paddingLeft: theme.spacing(9),
          paddingRight: theme.spacing(9),
          paddingBottom: theme.spacing(5)
        }}
      >
        {props.children}
      </DialogContent>
      <DialogActions>{props.actionComponents}</DialogActions>
    </Dialog>
  )
}
