import axios from "axios";
import React, { useState, useEffect } from "react";
import { blue } from "@mui/material/colors";
import CloseIcon from '@mui/icons-material/Close';

import { Alert, AlertTitle } from '@mui/material';

import { 
  Theme,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Typography,
  useTheme
} from "@mui/material";
import { useInterval } from "../hooks/useInterval";
import { FormattedMessage, useIntl } from "react-intl";

export interface LicenseInfo {
  version?: number;
  pointCount?: any;
  companyName?: string;
  expirationDate?: string;
  activationCode?: string;
  alertType?: string;
  pointsCount?: number;
  pointsLicensed?: number;
  pointsExceededNode?: string;
  scadaSystems?: Array<string>;
  scadaSystemsCount?: number;
  loaded?: string;
  gracePeriod?: boolean;
}

interface LicenseStatusDisplaySpecs {
  translationKey: string;
  backgroundColor: string;
}

interface AppInfo {
  hostName?: string;
  port?: string;
  appName?: string;
  appVersion?: string;
}

export type SetAppLicenseInfo = (licenseInfo: LicenseInfo) => void

interface AppLicenseInfoProps {
  licenseInfo?: LicenseInfo;
  setAppLicenseInfo?: SetAppLicenseInfo;
  theme: Theme;
}

export function AppLicenseInfo(props: AppLicenseInfoProps) {

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("license.loading");
  const [pollWaitTime, setPollWaitTime] = useState(0);
  const [licenseInfo, setLicenseInfo] = useState<LicenseInfo | undefined>(undefined);
  const [appInfo, setAppInfo] = useState<AppInfo | undefined>(undefined);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const loadLicenseInfo = () => {
    axios.get("/license/info").then((res) => {
      const data = res.data;
      const licenseInfo = {} as LicenseInfo;
      if (data.license) {
        setStatus(data.license.status);
        licenseInfo.companyName = data.license.companyName;
        licenseInfo.expirationDate = data.license.expirationDate;
        licenseInfo.alertType = data.license.status + ".alert.type";
        licenseInfo.loaded = data.license.pointCount?._loaded;
        licenseInfo.scadaSystemsCount = 1; // show 1 if no named scada pairs in the license
        licenseInfo.gracePeriod = data.license.gracePeriod;
        if (data.license.scadaSystems) {
          licenseInfo.scadaSystems = data.license.scadaSystems;
          if (data.license.scadaSystems.length > 0)
            licenseInfo.scadaSystemsCount = data.license.scadaSystems.length;
        }
      }
      if (data?.license?.pointCount?._loaded) {
        const totalCount = data.license.pointCount._total.value;
        const totalLimit = data.license.pointCount._total.limit;
        if (totalLimit !== 0 && totalLimit !== -1 && totalCount > totalLimit) {
          licenseInfo.pointsCount = totalCount;
          licenseInfo.pointsLicensed = totalLimit;
          licenseInfo.pointsExceededNode = "";
        } else {
          let exceededForNode = false;
          for (const key in data.license.pointCount) {
            if (
              Object.prototype.hasOwnProperty.call(data.license.pointCount, key)
            ) {
              if (key === "_loaded" || key === "_total") {
                continue;
              }
              const count = data.license.pointCount[key].value;
              const limit = data.license.pointCount[key].limit;
              if (limit && limit !== 0 && limit !== -1 && count > limit) {
                licenseInfo.pointsCount = count;
                licenseInfo.pointsLicensed = limit;
                licenseInfo.pointsExceededNode = key;
                exceededForNode = true;
                break;
              }
            }
          }
          if (!exceededForNode) {
            // all counts are within licensed limits,
            // but we still want to display current number of points
            licenseInfo.pointsCount = totalCount;
            licenseInfo.pointsLicensed = totalLimit;
            licenseInfo.pointsExceededNode = "";
          }
        }
      }
      setLicenseInfo(licenseInfo);

      // pass up to parent component(s)
      if (props.setAppLicenseInfo)
        props.setAppLicenseInfo(licenseInfo);
    });
  };

  useInterval(() => {
    if (!licenseInfo?.loaded || status.endsWith(".loading"))
      loadLicenseInfo();
    const newPollWaitTime = Math.min(10000, pollWaitTime + 5000);
    setPollWaitTime(newPollWaitTime);
  }, pollWaitTime);

  useEffect(()=>{
    if (!appInfo)
      loadAppInfo();
  }, []);

  const loadAppInfo = () => {
    axios.get("/info").then((res) => {
      const data = res.data;
      if (data) {
        const info = {} as AppInfo;
        info.hostName = data.hostName;
        info.port = data.port;
        if (data.app) {
          info.appName = data.app.name;
          info.appVersion = data.app.version;
        }
        setAppInfo(info);
      }
    });
  };

  const hasActivationCode = () => {
    return (
      licenseInfo?.activationCode && licenseInfo?.activationCode.length > 0
    );
  };

  const hasExpirationDate = () => {
    return (
      licenseInfo?.expirationDate && licenseInfo?.expirationDate.length > 0
    );
  };

  const isPerpetual = () => {
    return (
      licenseInfo?.expirationDate && licenseInfo?.expirationDate === "perpetual"
    );
  };

  const isLicenseExceeded = () => {
    return status.endsWith(".exceeded");
  };

  const isLicenseExceededTotalCount = (
    licenseStatus: string,
    pointsExceededNode: string
  ) => {
    return (
      status.endsWith(".exceeded") &&
      licenseInfo?.pointsExceededNode === ""
    );
  };

  const isLicenseExceededCountForNode = (
    licenseStatus: string,
    pointsExceededNode: string
  ) => {
    return (
      status.endsWith(".exceeded") && pointsExceededNode !== ""
    );
  };

  const isLicenseInGracePeriod = () => {
    return (
      licenseInfo?.gracePeriod && status.endsWith(".expired")
    );
  };


  // button logic
  
  const defaultButtonColor = props.theme.palette.secondary.main;
  const statuses: LicenseStatusDisplaySpecs[] = [
    { translationKey: ".loading", backgroundColor: defaultButtonColor },
    { translationKey: ".demo", backgroundColor: blue[500] },
    {
      translationKey: ".exceeded",
      backgroundColor: props.theme.palette.error.main,
    },
    {
      translationKey: ".expired",
      backgroundColor: props.theme.palette.error.main,
    },
    {
      translationKey: ".licensed",
      backgroundColor: props.theme.palette.success.main,
    },
  ];
  const licenseSplit = status.split(".");         // split the license status
  const licenseDetail = licenseSplit.pop() || ""; // license detail are after the last dot
  const statusInfo = statuses.find(o => o.translationKey.endsWith(licenseDetail));

  const intl = useIntl();

  const LicenseDialogRow = { paddingBottom: theme.spacing(1) };
  const LicenseAlert = { marginBottom: theme.spacing(1) };

  return (
    <>
      <Button
        sx={{
          fontSize: ".75rem",
          color: "#fff",
          maxHeight: "22px",
        }}
        style={{
          backgroundColor: statusInfo?.backgroundColor || blue[500],
        }}
        onClick={handleClickOpen}
         >
        <FormattedMessage id={status} />
      </Button>
      <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <Typography variant="h6">
          {intl.formatMessage({ id: "license.title" })}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: "absolute",
            right: props.theme.spacing(1),
            top: props.theme.spacing(1),
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {appInfo?.appVersion ? (
          <Typography 
            sx={{ ...LicenseDialogRow }}
            >
            <FormattedMessage
                id={"license.applicationVersion"}
                values={{ version: appInfo?.appVersion }}
            />
            <br />
          </Typography>
        ) : (
          <></>
        )}
        {licenseInfo?.companyName && (
          <Typography 
          sx={{ ...LicenseDialogRow }}
          >
          <FormattedMessage
              id={"license.company"}
              values={{ company: licenseInfo?.companyName }}
            />
            <br />
          </Typography>
        )}
        {hasExpirationDate() && (
          <Typography 
          sx={{ ...LicenseDialogRow }}
          >
            {isPerpetual() ? (
              <FormattedMessage
                id={"license.perpetual"}
              />
            ) : (
              <FormattedMessage
                id={"license.expiryDate"}
                values={{ expiryDate: licenseInfo?.expirationDate }}
              />
            )}
            <br />
          </Typography>
        )}
        {status && (
          <Typography 
          sx={{ ...LicenseDialogRow }}
          >
          <FormattedMessage
              id={"license.licenseStatus"}
              values={{
                licenseStatus: intl.formatMessage({
                  id: status,
                }),
              }}
            />
            <br />
          </Typography>
        )}
        {
          isLicenseInGracePeriod() &&
          <Alert severity="error" sx={{ ...LicenseAlert }}>
            <AlertTitle><FormattedMessage id="license.graceperiod.alert.title"/></AlertTitle>
            <FormattedMessage id="license.graceperiod.alert.message" />
          </Alert>
        }
        {licenseInfo && (
            <Typography sx={{ ...LicenseDialogRow }}>
              <FormattedMessage
                  id={"license.scadaSystems"}
                  values={{ countScadaPairs: licenseInfo?.scadaSystemsCount }}
              />
              <br />
            </Typography>
        )}
        {
          isLicenseExceeded() &&
          <Alert severity="error" sx={{ ...LicenseAlert }}>
            <AlertTitle><FormattedMessage id="license.exceeded.alert.title"/></AlertTitle>
            <FormattedMessage id="license.exceeded.alert.message" />
          </Alert>
        }
        {isLicenseExceededTotalCount(
          status || "",
          licenseInfo?.pointsExceededNode || ""
        ) && (
          <>
            <Typography 
            sx={{ ...LicenseDialogRow }}
            >
              <FormattedMessage
                id={"license.numberOfTagsTotal"}
                values={{ pointCount: licenseInfo?.pointsCount }}
              />
              <br />
            </Typography>
            <Typography 
            sx={{ ...LicenseDialogRow }}
            >
              <FormattedMessage
                id={"license.numberLicensedTotal"}
                values={{ pointCount: licenseInfo?.pointsLicensed }}
              />
              <br />
            </Typography>
          </>
        )}
        {isLicenseExceededCountForNode(
          status || "",
          licenseInfo?.pointsExceededNode || ""
        ) && (
          <>
            <Typography
            sx={{ ...LicenseDialogRow }}
            >
              <FormattedMessage
                id={"license.numberOfTags"}
                values={{
                  nodeName: licenseInfo?.pointsExceededNode,
                  pointCount: licenseInfo?.pointsCount,
                }}
              />
              <br />
            </Typography>
            <Typography
            sx={{ ...LicenseDialogRow }}
            >
              <FormattedMessage
                id={"license.numberLicensed"}
                values={{
                  nodeName: licenseInfo?.pointsExceededNode,
                  pointCount: licenseInfo?.pointsLicensed,
                }}
              />
              <br />
            </Typography>
          </>
        )}
        {!isLicenseExceeded() && (
          <Typography
          sx={{ ...LicenseDialogRow }}
          >
          <FormattedMessage
              id={"license.numberOfTagsTotal"}
              values={{ pointCount: licenseInfo?.pointsCount }}
            />
            <br />
          </Typography>
        )}
        {hasActivationCode() && (
          <Typography
          sx={{ ...LicenseDialogRow }}
          >
          <FormattedMessage
              id={"license.activationCode"}
              values={{ activationCode: licenseInfo?.activationCode }}
            />
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  </>
  );
}
