import { useTheme } from '@mui/material'
import { Group } from '@visx/group'
import { scaleOrdinal } from '@visx/scale'
import Pie, { PieArcDatum, ProvidedProps } from '@visx/shape/lib/shapes/Pie'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { animated, interpolate, useTransition } from 'react-spring'

const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 }

export class DonutDatum {
  category: string
  count?: number
  percentage: number
  color: string
}

export interface DonutChartProps {
  data: DonutDatum[]
  width: number
  height: number
  margin?: typeof defaultMargin
  animate?: boolean
  sort?: (a: DonutDatum, b: DonutDatum) => number
}

// accessor functions
const percentage = (d: DonutDatum) => d.percentage

export function DonutChart({
  data,
  width,
  height,
  margin = defaultMargin,
  animate = false,
  sort,
}: DonutChartProps) {
  const getCategoryColor = scaleOrdinal({
    domain: data.map((d) => d.category),
    range: data.map((d) => d.color)
  })

  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom
  const radius = Math.min(innerWidth, innerHeight) / 2
  const centerY = innerHeight / 2
  const centerX = innerWidth / 2
  const donutThickness = 10

  return (
    <svg width={width} height={height}>
      <rect width={width} height={height} fillOpacity={0} />
      <Group top={centerY + margin.top} left={centerX + margin.left}>
        <Pie
          top={centerY + margin.top}
          left={centerX + margin.left}
          data={data}
          pieValue={percentage}
          outerRadius={radius}
          innerRadius={radius - donutThickness}
          padAngle={0.005}
          pieSort={sort}
          
        >
          {(pie) => (
            <AnimatedPie<DonutDatum>
              animate={animate}
              {...pie}
              getKey={(arc) => arc.data.category}
              getColor={(arc) => getCategoryColor(arc.data.category)}
              top={centerY + margin.top}
              left={centerX + margin.left}
            />
          )}
        </Pie>
      </Group>
    </svg>
  )
}

// react-spring transition definitions
type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number }

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0
})
const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1
})

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<Datum>) => string;
  getColor: (d: PieArcDatum<Datum>) => string;
  delay?: number;
  top: number;
  left: number;
}

function AnimatedPie<Datum>({
  animate,
  arcs,
  path,
  getKey,
  getColor
}: AnimatedPieProps<Datum>) {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(
    arcs,
    // @ts-ignore react-spring doesn't like this overload
    {
      from: animate ? fromLeaveTransition : enterUpdateTransition,
      enter: enterUpdateTransition,
      update: enterUpdateTransition,
      leave: animate ? fromLeaveTransition : enterUpdateTransition,
      keys: getKey,
    }
  );
  const [currentDatum, setCurrentDatum] = useState({} as DonutDatum)
  const intl = useIntl()
  const theme = useTheme()
  return (
    <Group>
      {currentDatum.count && (
        <text textAnchor='middle'>
          <tspan x='0' dy='-0.2em' fontFamily={theme.typography.fontFamily}>
            {intl
              .formatMessage({ id: 'data.' + currentDatum.category })
              .concat('\n')
              .concat()}
          </tspan>
          <tspan x='0' dy='1.2em' fontFamily={theme.typography.fontFamily}>
            {intl.formatNumber(currentDatum.percentage, {
              style: 'percent',
              maximumFractionDigits: 2
            })}
          </tspan>
        </text>
      )}
      {
//      transitions.map(
//        ({
//          item: arc,
//          props,
//          key
//        }: {
//          item: PieArcDatum<Datum>
//          props: AnimatedStyles
//          key: string
//        }) => {
        transitions((props, arc, { key }) => {
          return (
            <g key={key}>
              <animated.path
                // compute interpolated path d attribute from intermediate angle values
                d={interpolate(
                  [props.startAngle, props.endAngle],
                  (startAngle, endAngle) =>
                    path({
                      ...arc,
                      //make angles negative to render donut segments clockwise
                      startAngle: -1 * startAngle,
                      endAngle: -1 * endAngle
                    })
                )}
                fill={getColor(arc)}
                onMouseOver={() => {
                  setCurrentDatum((arc.data as unknown) as DonutDatum)
                }}
                onMouseOut={() => {
                  setCurrentDatum({} as DonutDatum)
                }}
              />
            </g>
          )
        }
      )}
    </Group>
  )
}
