import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation, 
  matchPath 
} from 'react-router-dom'

import {GetNavItems} from "./service/ApiService"

// Hooks
import { useAuth }  from './hooks/useAuth';
import { useIntl } from "react-intl";
import { useTheme } from "@mui/material";

//Components
import HeaderBar from "./HeaderBar";
import MenuPanel, { MenuItem } from "./components/MenuPanel"
import { PageLoadingIndicator, ConfigPage, UsersPage } from "catapult-shared-ui-components"
import Help from './components/Help'
import TabPanel from './components/TabPanel'
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import { LicenseInfo } from "./components/AppLicenseInfo";

// Pages
import DashboardPage from "./pages/DashboardPage"
import DrillDown from "./pages/DrillDown"
import EventSourcesPage from "./pages/EventSourcesPage"
import LoginPage from "./users/LoginPage"
import BackfillPage from "./pages/BackfillPage";
import AlarmList from "./pages/AlarmList";
import LicenseExpired from "./pages/LicenseExpired";


//icons
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import StorageIcon from '@mui/icons-material/Storage';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';

interface NavItem {
    label: string;
    icon: string;
    id: string;
}

const NestedDrillDown : MenuItem[] = [
  {
    translationId: "alarm-health",
    url: ":range",
    pageComponent: <DrillDown />,
    nested: [
      {
        translationId: "alarm-health",
        url: ":tagName",
        pageComponent: <DrillDown />,
      }
    ]
  }
];

interface AppInfo {
    allowedTabs: NavItem[];
    isLoading: boolean;
}

const AppRouter = () => {   
    const intl = useIntl();
    const auth = useAuth();
    const theme = useTheme();
    const { pathname } = useLocation();

    const [appInfo, setAppInfo] = useState<AppInfo>({allowedTabs: [], isLoading: true });
    const [showHelp, setShowHelp] = useState(false);
    const [licenseInfo, setAppLicenseInfo] = useState<LicenseInfo | undefined>(undefined);

    useEffect(() => {

      if (matchPath("/license-expired", pathname)) {
        setAppInfo({allowedTabs : [], isLoading: false });
        return;
       }

      GetNavItems()
          .then((res) => {
            if (res.request.responseURL && res.request.responseURL.indexOf(res.config.url) < 0)
              window.location = (res.request.responseURL);
            else
              setAppInfo({allowedTabs : res.data.navItems ?? [], isLoading: false });
          })
          .catch((err) => {
            console.log("err", err);
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              auth.Logout();
              window.location.reload();
            }
          });
    }, [auth, pathname]);

    const setAppLicenseInfoOnce = (updatedValue: LicenseInfo) => {
        if (!licenseInfo && updatedValue)
            setAppLicenseInfo(updatedValue)
    }

    const openHelp = () => {
      console.log("open help");
      setShowHelp((prev) => (!prev));
    };
  
    const closeHelp = () => {
      setShowHelp(false);
    };

    const NestedAlarmView = [
        {
            translationId: "alarm-health",
            url: ":range",
            pageComponent: <AlarmList licenseInfo={licenseInfo}/>,
            nested: [
                {
                    translationId: "alarm-health",
                    url: ":tagName",
                    pageComponent: <AlarmList licenseInfo={licenseInfo} />,
                }
            ]
        }
    ];

    const menuConfig = [
        {
            translationId: "alarm-health",
            icon: <MultilineChartIcon />,
            url: "/dashboard",
            retriggerable: true,
            pageComponent: <DashboardPage />,
            nested: [
                {
                    translationId: "alarm-health",
                    url: "custom",
                    pageComponent: <DashboardPage />,
                    nested: [
                        {
                            translationId: "alarm-health",
                            url: ":range",
                            pageComponent: <DashboardPage />,
                        }
                    ]
                },
                {
                    translationId: "alarm-health",
                    url: "drill-down",
                    pageComponent: <>Missing drill-down type</>,
                    nested: [
                        {
                            translationId: "alarm-health",
                            url: ":dataset",
                            pageComponent: <>Missing range</>,
                            nested: NestedDrillDown,
                        }
                    ]
                },
                {
                    translationId: "alarm-health",
                    url: "alarm-list",
                    pageComponent: <>Missing drill-down type</>,
                    nested: [
                        {
                            translationId: "alarm-health",
                            url: ":dataset",
                            pageComponent: <>Missing range</>,
                            nested: NestedAlarmView,
                        }
                    ]
                }
            ]
        },
        {
            translationId: "event-sources",
            icon: <StorageIcon />,
            url: "/event-sources",
            pageComponent: <EventSourcesPage />,
        },
        {
            translationId: "backfilling",
            icon: <BarChartIcon />,
            url: "/backfill",
            pageComponent: <BackfillPage />,
        },
        {
            translationId: "config",
            icon: <SettingsIcon />,
            url: "/config",
            pageComponent: <ConfigPage />,
        },
        {
            translationId: "users",
            icon: <PeopleIcon />,
            url: "/users",
            pageComponent: <UsersPage />,
        },
    ];

    const defaultMenuItem = "/dashboard";

    const renderDefaultFallback = (menuItems: MenuItem[]) => {
      return (
        menuItems.filter(menuItemData => menuItemData.url === defaultMenuItem)
        .map(menuItemData => 
          <Route key={menuItemData.url} path="*" element={auth.HasAuthority(null) 
            ? <TabPanel index={menuItemData.url}> {menuItemData.pageComponent} </TabPanel> 
            : <Navigate to="/login" />} />)
      )
    }

    const renderRoutes = (menuItems: MenuItem[]) => {
      return (
        menuItems.map((menuItemData) => {
         
          // Not nested single route
          if (!menuItemData.nested) {
            return (
              <Route key={menuItemData.url} path={menuItemData.url} element={auth.HasAuthority(null) ? <TabPanel index={menuItemData.url}> {menuItemData.pageComponent} </TabPanel> : <Navigate to="/login" /> } />
            )
          }
          else
            return (
              <Route key={menuItemData.url} path={menuItemData.url} >
                <Route index element={auth.HasAuthority(null) ? <TabPanel index={menuItemData.url}> {menuItemData.pageComponent} </TabPanel> : <Navigate to="/login" /> } />
                {
                  renderRoutes(menuItemData.nested)
                }
              </Route>
            )
          }
        )
      )
    }

    return (
      <>
        {appInfo.isLoading ? <PageLoadingIndicator /> :
        <>
          <AppBar 
            sx={{
              width: "100%",
              flex: 1,
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <HeaderBar
              name={intl.formatMessage({ id: "alarmWatch" })}
              logoPath="./catapult-orange.png"
              setAppLicenseInfo={setAppLicenseInfoOnce}
              theme={theme}/>
            <MenuPanel 
                menuConfig={menuConfig}
                defaultTab={defaultMenuItem}
                allowedTabs={appInfo.allowedTabs}
                openHelp={openHelp}
              />
          </AppBar>
          {/* The next box is to let the routed components start at 104px under the menu */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "104px",
            }}
          />
          <Routes>
          {/* redirect root to the default menu item />*/}
          <Route path="/" element={<Navigate replace to={defaultMenuItem} />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/license-expired" element={<LicenseExpired />} />

          {renderRoutes(menuConfig
            .filter((menuItemData) =>
              appInfo.allowedTabs.find(
                (tab) => tab.id === menuItemData.translationId
                )
            ))}
          { renderDefaultFallback(menuConfig) }
          </Routes> 

          <Help 
            showHelp={showHelp}
            closeHelp={closeHelp}/>
        </>
        }
      </>
    )
}

export default AppRouter;
