import React, { useState, useContext, createContext } from 'react'

const authContext = createContext()

export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}> { children } </authContext.Provider>
}

export const useAuth = () => useContext(authContext)

function useProvideAuth() {
  const [authed, setAuthed] = useState(JSON.parse(localStorage.getItem("currentUser") || "{}"));

  return {
    authed,

    HasAuthority(rights) {
        return authed != null && authed.token;
    },

    GetUsername(){
        return authed ? authed.username : "";
    },

    GetUserId(){
        return authed ? authed.id : -1;
    },

    SetLoginInfo(state) {
        localStorage.setItem("currentUser", JSON.stringify(state));
        setAuthed(state);
      },

    Logout(state) {
        localStorage.setItem("currentUser", JSON.stringify("{}"));
        setAuthed({});
    }
    };
}