import React, { useState, useEffect, useRef, useLayoutEffect } from "react";

import { 
    FormDialog, 
    SaveButton
} from "catapult-shared-ui-components";
import {
  TextField
} from "@mui/material"

// hooks
import {useTheme}  from '@mui/material';
import { useIntl } from "react-intl";

import {SendResetPassword} from "../service/ApiService"


interface ResetPasswordDialogProps {
    children?: React.ReactNode;
    userId: Number;
    openChangePassword: boolean;
    onChangePassword: (value: boolean) => void;
}
  
function ResetPasswordDialog(props: ResetPasswordDialogProps) {

  const { children, userId, openChangePassword, onChangePassword, ...other } = props;
  const theme = useTheme();
  const intl = useIntl();
  const [setFocus, setFocusInput] = useState<HTMLInputElement | null>(null)
  const [passwordInput, setPasswordInput] = useState({currentPassword: "", newPassword: "", confirmPassword: ""});
  const [passwordIsUpdating, setPasswordIsUpdating] = useState(false);

  useEffect(() => {
    setPasswordInput({currentPassword: "", newPassword: "", confirmPassword: ""});
  }, [openChangePassword]);
  
  useEffect(() => {
    if (setFocus)
      setFocus.focus();
  }, [setFocus]);
  
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPasswordInput({
        ...passwordInput,
        [name]: value,
    });
  };

  const handleApply = () => {
    setPasswordIsUpdating(true);
    SendResetPassword(userId, passwordInput.currentPassword, passwordInput.newPassword)
    .then((res) => {
      onChangePassword(true);
    })
    .catch((err) => {
      onChangePassword(false);
    });
};


    return (
      <FormDialog
        open={openChangePassword}
        openSetter={(status) => onChangePassword(status) }
        i18nTitleKey="changePassword"
        actionComponents={[
            <SaveButton
              saving={passwordIsUpdating}
              disabled={
                passwordInput.newPassword === "" ||
                passwordInput.confirmPassword !== passwordInput.newPassword
              }
              isFormSubmit={false}
              onClick={() => handleApply() }
            />,
          ]}
      >   

          <TextField
            sx={{ margin: theme.spacing(1), }}
            error={passwordInput.currentPassword === ""}
            inputRef={el => {setFocusInput(el);}}
            helperText={
              passwordInput.currentPassword === ""
                ? intl.formatMessage({ id: "pleaseEnterCurrentPassword" })
                : " "
            }
            id="currentPassword"
            name="currentPassword"
            label={intl.formatMessage({ id: "currentPassword" })}
            type={"password"}
            onChange={handleInputChange}
          />
          <TextField
            sx={{ margin: theme.spacing(1), }}
            error={passwordInput.newPassword === ""}
            helperText={
              passwordInput.newPassword === ""
                ? intl.formatMessage({
                    id: "config.pleaseEnterNewPassword",
                  })
                : " "
            }
            id="newPassword"
            name="newPassword"
            label={intl.formatMessage({ id: "users.newPassword" })}
            type={"password"}
            onChange={handleInputChange}
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off",
              },
            }}
          />
          <TextField
            sx={{ margin: theme.spacing(1), }}
            error={passwordInput.confirmPassword !== passwordInput.newPassword}
            helperText={
              passwordInput.confirmPassword !== passwordInput.newPassword
                ? intl.formatMessage({ id: "config.passwordsMustMatch" })
                : " "
            }
            id="confirmPassword"
            name="confirmPassword"
            label={intl.formatMessage({ id: "users.confirmPassword" })}
            type="password"
            onChange={handleInputChange}
            inputProps={{
              autocomplete: "confirm-password",
              form: {
                autocomplete: "off",
              },
            }}
          />
        </FormDialog>
    );
}
  
export default ResetPasswordDialog;    