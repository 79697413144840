import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'

import { Button, Container, FormControl, IconButton, InputLabel, Menu, MenuItem, Select, TextField, Typography } from '@mui/material'
import { DataGridPro, GridRenderCellParams, GridColDef } from '@mui/x-data-grid-pro'
import Box from '@mui/material/Box';

// Icons
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import axios from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormDialog } from './FormDialog'
import { PageLoadingIndicator } from './PageLoadingIndicator'
import { SaveButton } from './SaveButton'

import { useSnackbar } from "notistack"

interface User {
  id: number,
  username: string,
  firstName: string,
  lastName: string,
  roles: string,
  authType: number,
  password?: string,
}

export function UsersPage() {

  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl()

  const [usersList, setUsersList] = useState([] as User[]);
  const [currentUserString] = useState(
    localStorage.getItem("currentUser")
  );
  const [currentUser] = useState(
    currentUserString ? JSON.parse(currentUserString) : {}
  );
  const [setFocus, setFocusInput] = useState<HTMLInputElement | null>(null)
  const [isLoading, setLoading] = useState(true)
  const [isSaving, setSaving] = useState(false)
  const [isResettingPassword, setResettingPassword] = useState(false)
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false)
  const [openEditUserDialog, setOpenEditUserDialog] = useState(false)
  const [userToEdit, setUserToEdit] = useState({} as User)
  const [newUsername, setNewUsername] = useState('');
  const [newPasswordToSave, setNewPasswordToSave] = useState('');
  const [passwordConfirmationValue, setPasswordConfirmationValue] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [errorOrSuccessUser, setErrorOrSuccessUser] = useState<User>();
  const [openPasswordResetDialog, setOpenPasswordResetDialog] = useState(false)
  const [openMoreActionsMenu, setOpenMoreActionsMenu] = useState(false)

  const getUsers = useCallback(() => {
    setLoading(true)
    axios
      .get(`/user/list`, {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      })
      .then((res) => {
        setUsersList(res.data.userList)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        enqueueSnackbar(intl.formatMessage({ id: "users.problemLoadingUsers"}));
      })
  }, [currentUser.token])

  const addUser = useCallback((e: React.MouseEvent<HTMLElement>, username: string, password: string) => {
    e.preventDefault()
    setSaving(true)
    axios
      .post('/user/add', null, {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
        params: {
          id: -1,
          username: username,
          password: password,
          roles: 'user',
          authType: 0
        }
      })
      .then((res) => {
        if (res.data.user.id === -1) {
          setSaving(false)
          enqueueSnackbar(intl.formatMessage({ id: "users.problemAddingUser"}));
        } else {
          setUsersList(usersList.concat([res.data.user] as User[]))
          setSaving(false)
          enqueueSnackbar(intl.formatMessage({ id: "users.userAdded"}), { variant: "success" });
          setOpenAddUserDialog(false)
        }
      })
      .catch(() => {
        setSaving(false)
        enqueueSnackbar(intl.formatMessage({ id: "users.problemAddingUser"}));
      })
  }, [currentUser.token, usersList, setUsersList])

  const deleteUser = useCallback((e: React.MouseEvent<HTMLElement>, userId: number, username: string) => {
    e.preventDefault()
    axios
      .delete('user/delete', {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
        params: {
          id: userId,
          username: username,
        }
      })
      .then((res) => {
        setUsersList(usersList.filter((user) => user.id !== userId))
        enqueueSnackbar(intl.formatMessage({ id: "users.userDeleted"}), { variant: "success" });
      })
      .catch(() => {
        setErrorOrSuccessUser(usersList.find((user) => user.id === userId))
        enqueueSnackbar(intl.formatMessage({ id: "users.problemDeletingUser"}, { userID: userId, username: username }));
      })
  }, [currentUser.token, usersList])

  const updateUser = useCallback((e: React.MouseEvent<HTMLElement>, user: User) => {
    e.preventDefault()
    axios
      .put('user/update', null, {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
        params: {
          id: user.id,
          username: user.username,
          firstName: user.firstName,
          lastName: user.lastName,
          roles: user.roles,
          authType: user.authType
        }
      })
      .then((res) => {
        enqueueSnackbar(intl.formatMessage({id: "users.updatedUser"}), { variant: "success" });
        setOpenEditUserDialog(false);
        getUsers();
      })
      .catch(() => {
        setErrorOrSuccessUser(user);
        enqueueSnackbar(intl.formatMessage({ id: "users.problemUpdatingUser"}, { userID: user?.id, username: user?.username }));
      })
      
  }, [currentUser.token, getUsers])

  const handleActionsMenuClose = () => {
    setAnchorEl(null)
  }

  const resetPasswordForUser = useCallback((userId: number | undefined, newPassword: string) => {
    if (typeof userId === 'undefined') {
      return
    }
    setResettingPassword(true);         // Block list while updating
    setOpenPasswordResetDialog(false);  // close dialog
    axios
      .post(
        `user/${userId}/reset_password`,
        { newPassword: newPassword },
        {
          headers: {
            Authorization: `Bearer ${currentUser.token}`
          }
        }
      )
      .then((res) => {
        const user = usersList.find((user) => user.id === userId);
        setErrorOrSuccessUser(user)
        setResettingPassword(false)
        enqueueSnackbar(intl.formatMessage({ id: "users.passwordReset" }, { userID: user?.id, username: user?.username }), { variant: "success" });
      })
      .catch((res) => {
        const user = usersList.find((user) => user.id === userId);
        setErrorOrSuccessUser(user)
        setResettingPassword(false)
        enqueueSnackbar(intl.formatMessage({ id: "users.errorResettingPassword" }, { userID: user?.id, username: user?.username }));
      })
  }, [currentUser.token, usersList])

  const columns: GridColDef[] = [
    { field: 'id', headerName: intl.formatMessage({id: 'users.id'}), type: 'number', flex: 0, resizable: true },
    { field: 'username', headerName: intl.formatMessage({id: 'users.username'}), flex: 1, resizable: true},
    { field: 'firstName', headerName: intl.formatMessage({id: 'users.firstName'}), flex: 1, resizable: true},
    { field: 'lastName', headerName: intl.formatMessage({id: 'users.lastName'}), flex: 1, resizable: true},
    { field: 'roles', headerName: intl.formatMessage({id: 'users.roles'}), flex: 1, resizable: true},
    { field: 'authType', headerName: intl.formatMessage({id: 'users.authType'}), type: 'number',
      flex: 1, resizable: true, align: 'left', headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => {
        return <FormattedMessage id={params.value ? 'users.authType.activeDirectory' : 'users.authType.builtIn'} />
      }
    },
    { field: 'actionsPlaceholder', headerName: intl.formatMessage({id: 'users.actions'}), flex: 1, sortable: false,
      resizable: true, filterable: false, headerAlign: 'right', hideable: false, disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        const id: number = params.getValue(params.row.id, 'id')?.valueOf() as number
        const username: string = params.getValue(params.row.id, 'username')?.toString() as string
        const user: User | undefined = {...usersList.find((el) => el.id === id)} as User;
        return (<div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
          <div style={{flex: 1}}/>
          {user && <div><IconButton style={{flex: 0}} onClick={(e) => {
            setUserToEdit(user)
            setOpenEditUserDialog(true)
          }}>
            <EditIcon/>
          </IconButton>
          <IconButton style={{flex: 0}} onClick={
            (e) => {
              window.confirm(intl.formatMessage({id: 'users.confirmDeletion'}, {username: username, userId: id}))
              && deleteUser(e, id, username)
            }
          }>
            <DeleteIcon/>
          </IconButton>
          <IconButton
            style={{flex: 0}}
            onClick={(e) => {
              setOpenMoreActionsMenu(true)
              setAnchorEl(e.currentTarget)
            }}
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="more-account-actions"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            keepMounted
            open={Boolean(anchorEl) && !openPasswordResetDialog && openMoreActionsMenu}
            onClose={handleActionsMenuClose}
          >
            <MenuItem onClick={() => {
              setErrorOrSuccessUser(user)
              setOpenMoreActionsMenu(false)
              setOpenPasswordResetDialog(true)
            }}>
              {intl.formatMessage({ id: 'users.resetPassword' })}
            </MenuItem>
          </Menu>
          </div>}
        </div>)
      }
    }
  ];

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  // Handle the focus for a dialog
  useEffect(() => {
    if (setFocus)
      setFocus.focus();
  }, [setFocus]);


  return (
    <Container sx={{ width: '100%', height: '100%', }}>
      {(isLoading || isResettingPassword) && <PageLoadingIndicator />}
      {!isLoading && (
        <div style={{ height: '100%' }}>
          <Typography variant='h3' style={{ padding: "0.5em 0 0.5em 0", flex: 0 }}>
            <FormattedMessage id='users.userManagement' />
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '80%', maxHeight: '40em', flex: 1, marginBottom: 2 }}>
            <DataGridPro autoHeight rows={usersList} columns={columns} pagination disableSelectionOnClick disableColumnReorder disableColumnSelector/>
          </Box>
          <Button variant='contained' size='large' color='primary' style={{ flex: 0 }} onClick={() => setOpenAddUserDialog(true)}>
            <FormattedMessage id='users.add' />
            <AddIcon />
          </Button>
          <FormDialog open={openAddUserDialog} openSetter={setOpenAddUserDialog} i18nTitleKey='users.addNewUser'
            actionComponents={
              [
                (<SaveButton
                  saving={isSaving}
                  disabled={newUsername === '' || newPasswordToSave === '' || passwordConfirmationValue !== newPasswordToSave}
                  isFormSubmit={false} //to avoid nested form element antipattern
                  onClick={(e) => addUser(e, newUsername, newPasswordToSave)}
                />)
              ]
            }>
              <TextField
                inputRef={el => {setFocusInput(el);}}
                sx={{ my: 1, mx: 0 }}
                error={newUsername === ''}
                helperText={newUsername === '' ? intl.formatMessage({ id: 'users.pleaseEnterAUsername' }) : " "}
                id={intl.formatMessage({ id: 'users.newUsername' })}
                label={intl.formatMessage({ id: 'users.newUsername' })}
                type={'text'}
                onChange={(e: ChangeEvent<HTMLInputElement>) => { setNewUsername(e.target.value) } }
                // This component is for specifying a new user, therefore autofill is disabled.
                inputProps={{
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }} />
              <TextField
                sx={{ my: 1, mx: 0 }}
                error={newPasswordToSave === ''}
                helperText={newPasswordToSave === '' ? intl.formatMessage({ id: 'config.pleaseEnterNewPassword' }) : " "}
                id={'users.newPassword'}
                label={intl.formatMessage({ id: 'users.newPassword' })}
                type={'password'}
                onChange={(e: ChangeEvent<HTMLInputElement>) => { setNewPasswordToSave(e.target.value) } }
                // This component is for specifying a new password, therefore autofill is disabled.
                inputProps={{
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }} />
              <TextField
                sx={{ my: 1, mx: 0 }}
                error={passwordConfirmationValue !== newPasswordToSave}
                helperText={passwordConfirmationValue !== newPasswordToSave ? intl.formatMessage({ id: 'config.passwordsMustMatch' }) : " "}
                id='users.confirmPassword'
                label={intl.formatMessage({ id: 'users.confirmPassword' })}
                type='password'
                onChange={(e) => setPasswordConfirmationValue(e.target.value)}
                // This component is for confirming a new password, not logging in, therefore autofill is disabled.
                inputProps={{
                  autocomplete: 'confirm-password',
                  form: {
                    autocomplete: 'off',
                  },
                }} />
          </FormDialog>
          <FormDialog open={openEditUserDialog} openSetter={setOpenEditUserDialog} i18nTitleKey='users.editUser'
            actionComponents={[
              <SaveButton
              saving={isSaving}
              isFormSubmit={false} //to avoid nested form element antipattern
              onClick={(e) => updateUser(e, userToEdit)} />
            ]}
          >
            <TextField
              inputRef={el => {setFocusInput(el);}}
              sx={{ my: 1, mx: 0 }}
              id={intl.formatMessage({ id: 'users.firstName' })}
              label={intl.formatMessage({ id: 'users.firstName' })}
              type={'text'}
              defaultValue={userToEdit.firstName}
              onChange={(e) => { userToEdit.firstName = e.target.value } } />
            <TextField
              sx={{ my: 1, mx: 0 }}
              id={'users.lastName'}
              label={intl.formatMessage({ id: 'users.lastName' })}
              type={'text'}
              defaultValue={userToEdit.lastName}
              onChange={(e) => { userToEdit.lastName = e.target.value } } />
            <FormControl sx={{ my: 1, mx: 0 }} >
              <InputLabel id='user-auth-type-label'>
                <FormattedMessage id='users.authType' />
              </InputLabel>
              <Select
                id='users.authType'
                defaultValue={userToEdit.authType}
                labelId='user-auth-type-label'
                onChange={(e) =>
                  (userToEdit.authType = e.target.value as number)
                }
              >
                <MenuItem key={'users.authType.builtIn'} value={0}>
                  <FormattedMessage id={'users.authType.builtIn'} />
                </MenuItem>
                <MenuItem key={'users.authType.activeDirectory'} value={1}>
                  <FormattedMessage id={'users.authType.activeDirectory'} />
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
                sx={{ my: 1, mx: 0 }}
                id='users.roles'
              label={intl.formatMessage({ id: 'users.roles' })}
              type='text'
              defaultValue={userToEdit.roles}
              onChange={(e) => { userToEdit.roles = e.target.value } } />
        </FormDialog>
        <FormDialog
            open={openPasswordResetDialog}
            openSetter={setOpenPasswordResetDialog}
            i18nTitleKey="users.resetPassword"
            actionComponents={[
              <SaveButton
                saving={isResettingPassword}
                disabled={
                  newPasswordToSave === "" ||
                  passwordConfirmationValue !== newPasswordToSave
                }
                isFormSubmit={false}
                onClick={(e) => {resetPasswordForUser(errorOrSuccessUser?.id, newPasswordToSave); console.log('in')}}
              />,
            ]}
          >
            <TextField
              inputRef={el => {setFocusInput(el);}}
              sx={{ my: 1, mx: 0 }}
              error={newPasswordToSave === ""}
              helperText={
                newPasswordToSave === ""
                  ? intl.formatMessage({
                      id: "config.pleaseEnterNewPassword",
                    })
                  : " "
              }
              id={"users.newPassword"}
              label={intl.formatMessage({ id: "users.newPassword" })}
              type={"password"}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setNewPasswordToSave(e.target.value);
              }}
              // This component is for specifying a new password, therefore autofill is disabled.
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
            />
            <TextField
                sx={{ my: 1, mx: 0 }}
                error={passwordConfirmationValue !== newPasswordToSave}
              helperText={
                passwordConfirmationValue !== newPasswordToSave
                  ? intl.formatMessage({ id: "config.passwordsMustMatch" })
                  : " "
              }
              id="users.confirmPassword"
              label={intl.formatMessage({ id: "users.confirmPassword" })}
              type="password"
              onChange={(e) => setPasswordConfirmationValue(e.target.value)}
              // This component is for confirming a new password, not logging in, therefore autofill is disabled.
              inputProps={{
                autocomplete: "confirm-password",
                form: {
                  autocomplete: "off",
                },
              }}
            />
          </FormDialog>
        </div>
      )}
    </Container>
  )
}
