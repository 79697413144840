import {  useState } from 'react'

//export type PageLoadingStatus = 'init' | 'loading' | 'loaded' | 'error';

export enum PageLoadingStatus {
    INIT = "init",
    LOADING = "loading",
    LOADED = "loaded",
    ERROR = "error",
  }

export const usePageStatus = (initialState : PageLoadingStatus = PageLoadingStatus.INIT) => {
    const [state, setState] = useState<PageLoadingStatus>(initialState);
  
    const isPageLoading = () => {
        return state == PageLoadingStatus.INIT || state == PageLoadingStatus.LOADING;
    };
    
    const isPageLoaded = () => {
        return state == PageLoadingStatus.LOADED || state == PageLoadingStatus.ERROR;
    };
    
    const isPageError = () => {
        return state == PageLoadingStatus.ERROR;
    };
  
    return {
        state,
        setStatus(status: PageLoadingStatus) { setState(status);},
        pageLoading: isPageLoading,
        pageLoaded: isPageLoaded,
        pageError: isPageError,
    };
  };