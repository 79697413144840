import React, { useEffect, useState } from "react";
import { 
    Box,
    Typography
} from "@mui/material";

  
function LicenseExpired() {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: "column",
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant={'h3'} sx={{m:2}}>License has expired</Typography>
        <Box sx={{ alignItems: 'center', }}>
          Please contact your supplier for renewal.
        </Box>
        <Box sx={{ alignItems: 'center', }}>
          Meanwhile background services will keep running as usual, receiving and processing data.
        </Box>
      </Box>
  )
}

export default LicenseExpired;
