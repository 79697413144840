import React from 'react';
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import messages_en_nz from "./translations/en-NZ.json";
import { IntlProvider } from 'react-intl';

const messages = {
  'en-NZ': messages_en_nz
};

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');

function getClientLocale() {
    if (window.navigator.languages) {
        return window.navigator.languages[0];
    } else {
        return window.navigator.language;
    }
}

const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <IntlProvider locale={getClientLocale()} messages={messages['en-NZ']}>
        <App />
    </IntlProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
