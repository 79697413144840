import React from "react";

import { Button, CircularProgress } from "@mui/material";
import { CSSProperties } from "react";
import { FormattedMessage } from "react-intl";

export interface SaveButtonProps {
  style?: CSSProperties,
  saving: boolean,
  disabled?: boolean,
  isFormSubmit?: boolean,
  formId?: string,
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

export function SaveButton(props: SaveButtonProps) {
  return <Button
    size="large"
    variant="contained"
    color="primary"
    style={{
      position: "relative",
      display: 'block',
      ...props.style,
    }}
    disabled={props.saving || props.disabled}
    type={props.isFormSubmit ? 'submit' : 'button'}
    form={props.formId}
    onClick={props.onClick}
  >
    <FormattedMessage id="apply"/>
    {props.saving && (
      <CircularProgress
        size={24}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: -12,
          marginLeft: -12,
        }}
      ></CircularProgress>
    )}
  </Button>
}