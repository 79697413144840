import { Theme, useTheme } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import "./HeaderBar.scss";
import { AppLicenseInfo, SetAppLicenseInfo } from "./components/AppLicenseInfo";

interface HeaderProps {
  name: string;
  theme: Theme;
  logoPath: string;
  className?: string;
  setAppLicenseInfo?: SetAppLicenseInfo;
}

function HeaderBar(props: HeaderProps) {
  const theme = useTheme();
  return (
    <nav
      className = "navbar navbar-dark bg-dark"
    >
      <div className="container-fluid">
        <div className="navbar-header">
          <div className="navbar-brand">
            <img
              className="logo"
              src="/logo.png"
              width="32"
              height="32"
              alt=""
            />
            {props.name}
          </div>
        </div>
        <div className="navbar-nav ml-auto">
          <li className="nav-item">
          <AppLicenseInfo theme={theme} setAppLicenseInfo={props.setAppLicenseInfo}/>
          </li>
          <li className="nav-item">
            <FormattedMessage id={"poweredBy"} />
            <img
              className="logo-top-right"
              src={"/"+props.logoPath}
              width="80"
              height="16"
              alt=""
            />
          </li>
        </div>
      </div>
    </nav>
  );
}

export default HeaderBar;