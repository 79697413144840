import React from 'react'
import {
    Route,
    Routes
} from 'react-router-dom'


import {
    Box,
    IconButton,
    Drawer
} from "@mui/material";

// icons
import CloseIcon from '@mui/icons-material/Close';
 
import {useAuth}  from '../hooks/useAuth';

interface HelpProps {
    showHelp: boolean;
    closeHelp: Function;
    marginTop?: number
}
 
const Help = (props: HelpProps) => {
    const { showHelp, closeHelp, marginTop } = props;
    const auth = useAuth();

    const drawerWidth = "30em";
    const drawerTop = marginTop ?? 104 + "px";

    return (
        <Drawer
        variant="persistent"
        anchor="right"
        sx={{
            ...(showHelp && { mt: drawerTop, width: drawerWidth }),
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { mt: drawerTop, width: drawerWidth, boxSizing: 'border-box' },
        }}        
        open={showHelp}
        >
        <Box sx={{ height: "100%", width: "100%", overflow: 'auto'  }}>
        <IconButton
            aria-label="close"
            onClick={() => { closeHelp()}}
            sx={{
                position: "absolute",
                right: '0.5em',
            }}
        >
          <CloseIcon />
        </IconButton>
        <Routes>
        <Route
            path="/"
            element={
                <iframe
                title="help"
                src={"/help/user/"}
                style={{ height: "100%", width: "100%", border: "none" }}
                />
            }
        />
        <Route
            path="/dashboard"
            element={
                <iframe
                title="help"
                src={"/help/user/"}
                style={{ height: "100%", width: "100%", border: "none" }}
                />
            }
        />
        <Route
            path={"/dashboard/drill-down/*"}
            element={
                <iframe
                title="help"
                src={"/help/user/drilldown/"}
                style={{ height: "100%", width: "100%", border: "none" }}
                />
            }
        />
        <Route
            path={"/dashboard/alarm-list/*"}
            element={
                <iframe
                title="help"
                src={"/help/user/alarms/"}
                style={{ height: "100%", width: "100%", border: "none" }}
                />
            }
        />
        {auth.HasAuthority(null) && (
            <Route
            path="/config"
            element={
                <iframe
                    title="help"
                    src={"/help/admin/"}
                    style={{ height: "100%", width: "100%", border: "none" }}
                />
            }
            />
        )}
        {auth.HasAuthority(null) && (
            <Route
            path="/users"
            element={
                <iframe
                    title="help"
                    src={"/help/admin/users/"}
                    style={{ height: "100%", width: "100%", border: "none" }}
                />
            }
            />
        )}
        {auth.HasAuthority(null) && (
            <Route
            path="/backfill"
            element={
                <iframe
                    title="help"
                    src={"/help/admin/backfill/"}
                    style={{ height: "100%", width: "100%", border: "none" }}
                />
            }
            />
        )}

        </Routes>
        </Box>

        </Drawer>
    );
}

export default Help;