import React from "react";
import Box from '@mui/material/Box';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
  }
  
function TabPanel(props: TabPanelProps) {
    const { children, index, ...other } = props;

    return (
    <Box
        role="tabpanel"
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        sx={{ 
            display: "flex",
            flexDirection: "column",
            flex: "1",
            overflow: "auto",
        }}
        >
        <Box p={3}>
            {children}
        </Box>
    </Box>
    );
}
  

export default TabPanel;