import React, { useState, useEffect } from "react";
import { useLocation, matchPath, useNavigate } from "react-router";

import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import ThisUserMenu from "../users/ThisUserMenu"

// Hooks
import {useTheme}  from '@mui/material';
import { FormattedMessage, useIntl } from "react-intl";

// icons
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


/**
 *  The component will render the navigation menu
 *   
 *  Standard it will add a Help and User button
 * 
 */

 interface AllowedItem { 
    id: string; 
    icon: string; 
    label: string 
}

export interface MenuItem {
    translationId: string;
    icon?: string | React.ReactElement;
    url: string;
    nested? : MenuItem[];
    retriggerable?: boolean;
    pageComponent?: React.ReactElement;
}

interface MenuPanelProps {
    children?: React.ReactNode;
    menuConfig: MenuItem[];
    defaultTab: string;
    allowedTabs: AllowedItem[];
    openHelp: Function;
}
  
function MenuPanel(props: MenuPanelProps) {
    const { children, menuConfig, defaultTab, allowedTabs, openHelp, ...other } = props;

    const theme = useTheme();
    const intl = useIntl();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [currentTab, setCurrentTab] = useState(defaultTab);


    useEffect(() => {
        let anyMatch = false;
        menuConfig.map((menuItemData) => {
            const match = matchPath({ path: menuItemData.url }, pathname);
            if (match) {
                setCurrentTab(menuItemData.url);
                anyMatch = true;
            }
        });
        // No match fallback to first item
        if (!anyMatch)
          setCurrentTab(defaultTab);

    }, [pathname]);
      
      
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
        navigate(newValue);
    };

    const handleOnClick = (event: React.BaseSyntheticEvent<{}>) => {

        // The onclick only gives us an event and the target clicked
        // a tab is an extended class of button, we need to find the tab button base.

        let lTab = event.target;
        while (lTab != null && lTab.type !== "button")
            lTab = lTab.parentElement;

        if (lTab == null)
            return;

        // We have the root tab item, now we can access the tab items like textContent
        // and find it in the menuConfig array, to trigger the URL again. 
        menuConfig.map((menuItemData) => {

            if (menuItemData.retriggerable) {
                const lMenuLabel = intl.formatMessage({
                    id: menuItemData.translationId,
                })
                if (lMenuLabel === lTab.textContent) {
                    navigate(menuItemData.url);
                }
            }
        });
    };

            
    function a11yProps(index: any) {
        return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
        };
    }
  

    return (
    <Paper elevation={3} square
        sx={{
            display: "flex",
            flexDirection: "row",
            height: "72px",
          }}
        {...other}
    >
        <Tabs
            value={currentTab}
            sx={{ flex: 1, }}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTab}
            >
            {menuConfig
            .filter((menuItemData) =>
                allowedTabs.find(
                (tab) => tab.id === menuItemData.translationId
                )
            )
          
            .map((menuItemData, idx) => (
                <Tab
                onClick={handleOnClick}
                value={menuItemData.url}
                tabIndex={idx}
                label={intl.formatMessage({
                    id: menuItemData.translationId,
                })}
                icon={menuItemData.icon}
                {...a11yProps(idx)}

                />
            ))}
        </Tabs>
        <Button
            color="secondary"
            sx={{
                flexGrow: 0,
                borderRadius: 0,
                padding: theme.spacing(2),
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
              }}
            aria-controls="help"
            aria-haspopup
            startIcon={<HelpOutlineIcon/>}
            onClick={() => openHelp()}
        >
            <FormattedMessage id="help" />
        </Button>
        <ThisUserMenu />
    </Paper>
    );
}
  
export default MenuPanel;