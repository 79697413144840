import React from "react";

// UI components
import { Container, Typography } from "@mui/material";

// hooks
import {useTheme}  from '@mui/material';
import { FormattedMessage, useIntl } from "react-intl";


interface DashboardPageProps {
    children?: React.ReactNode;
}
  
function DashboardPage(props: DashboardPageProps) {

    const { children, ...other } = props;

    const theme = useTheme();
    const intl = useIntl();

    return (
        <Container>
            <Typography variant="h3" style={{padding: "0.5em 0"}}><FormattedMessage id={'eventSources.title'}/></Typography>
        </Container>
    );
}
  
export default DashboardPage;    