/*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
import React, { useEffect, useState } from "react";
import {Link, useLocation, matchPath, useParams } from "react-router-dom"


// UI components
import { 
    Typography, 
    Tooltip, 
    Box, 
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent,
    MenuItem,
    TextField
} from "@mui/material";
import { 
    PageLoadingIndicator,
    DonutChart,
    SingleValueDonutChart,
    AnalyticValue
} from "catapult-shared-ui-components"
import { DonutDatum } from "catapult-shared-ui-components/dist/DonutChart";
import { 
    getGridStringOperators,
    DataGridPro, 
    GridRenderCellParams, 
    GridColDef,
    GridToolbar,
} from '@mui/x-data-grid-pro'

import { getExtendedStringFilterOptions } from "../helpers/GridFilterHelpers"

import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';

// i18n
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import enNZ from "dayjs/locale/en-nz"
import en from "dayjs/locale/en"

// Hooks
import { useTheme }  from '@mui/material';
import { useSnackbar } from "notistack"
import { FormattedMessage, useIntl } from "react-intl";

// Api calls helper
import {
    GetAlarmHealthFormats,
    GetAlarmSummary,
    GetAlarmSummaryFields
} from "../service/ApiService"

import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {Locale} from "dayjs/locale/*";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)

interface LocaleMap {
    [key: string]: Locale | undefined
}

const locales: LocaleMap = {
    "en-NZ": enNZ
}

interface DateFormatMap {
    [key: string]: string | undefined
}

const dateFormats: DateFormatMap = {
    "en": "MMM D, YYYY", // English (US)
    "en-nz": "D MMM YYYY", // English (New Zealand)
    "en-au": "D MMM YYYY", // English (Australia)
    "en-ca": "MMM D, YYYY", // English (Canada)
    "en-gb": "D MMM YYYY", // English (United Kingdom)
    "es": "D MMM YYYY", // Spanish
    "fr-ca": "D MMM YYYY", // French (Canada)
    "nl": "D MMM YYYY", // Dutch
}

// short date formats with 2-digit year
const dateFormatsYY: DateFormatMap = {
    "en": "MMM D, YY", // English (US)
    "en-nz": "D MMM YY", // English (New Zealand)
    "en-au": "D MMM YY", // English (Australia)
    "en-ca": "MMM D, YY", // English (Canada)
    "en-gb": "D MMM YY", // English (United Kingdom)
    "es": "D MMM YY", // Spanish
    "fr-ca": "D MMM YY", // French (Canada)
    "nl": "D MMM YY", // Dutch
}

// just day and month (without year)
const dayMonthFormats: DateFormatMap = {
    "en": "MMM D", // English (US)
    "en-nz": "D MMM", // English (New Zealand)
    "en-au": "D MMM", // English (Australia)
    "en-ca": "MMM D", // English (Canada)
    "en-gb": "D MMM", // English (United Kingdom)
    "es": "D MMM", // Spanish
    "fr-ca": "D MMM", // French (Canada)
    "nl": "D MMM", // Dutch
}

interface CommonFormat {
    kind: "year" | "quarter" | "month" | "week" | "day";
    num: number;
    previousPeriods: number;
}

const granularityItems = ['m', 'd', 'w', 'q'] as const;
type granularityType = typeof granularityItems[number];
interface PeriodData {
    startDate: Date | null;
    endDate: Date | null;
    numberOfPreviousPeriods: number;
    granularity: granularityType;
}

interface Fields {
    path: string; 
    name: string; 
    type: string; 
    flexGrow: number;
    frozen?: boolean;
}

interface DatePeriod {
    kind: "year" | "quarter" | "month" | "week" | "day" | "dateRange";
    from: string;
    to: string;
}

function GetDateRange(period: PeriodData) {
    if (period.startDate && period.endDate) {
        try {
            const start = dayjs(period.startDate).toISOString()
            const end = dayjs(period.endDate).toISOString()
            return `${start}..${end}`
        } catch (e) {
            console.log(e);
        }
    }
    return undefined;
}

const LabelColumn = (params: GridRenderCellParams) => {
    return (
    <Tooltip title={params.row.tooltip} placement='right'>
        <Box>{params.value}</Box>
    </Tooltip>
    );
}

const PieColumn = (params: GridRenderCellParams) => {
    // Invalid data
    if (typeof params.value !== "string")
        return <>{params.value}</>;

    const [highPercentage, mediumPercentage, lowPercentage, ] = (params.value as string)
        .split("/")
        .map((el) => Number.parseFloat(el));

      // do not display if the metric value could not be parsed
      if (
        Number.isNaN(highPercentage) ||
        Number.isNaN(mediumPercentage) ||
        Number.isNaN(lowPercentage)
      ) {
        return <>{params.value}</>;
      }
      return (
        <DonutChart
          data={[
            {
              category: "high",
              count: highPercentage,
              percentage: highPercentage / 100,
              color: "rgb(243, 67, 54)",
            },
            {
              category: "medium",
              count: mediumPercentage,
              percentage: mediumPercentage / 100,
              color: "orange",
            },
            {
              category: "low",
              count: lowPercentage,
              percentage: lowPercentage / 100,
              color: "rgb(90, 191, 248)",
            },
          ]}
          width={80}
          height={80}
          sort={(a: DonutDatum, b: DonutDatum) => {
            const sortOrder = ["rgb(243, 67, 54)", "orange", "rgb(90, 191, 248)"]
            return sortOrder.indexOf(b.color) - sortOrder.indexOf(a.color)
          }}
        />
      );
}
 
const PctCircleColumn = (params: GridRenderCellParams, ranges: string[], fields: Fields[]) => {

    // do not display if the metric value could not be parsed
    let displayValue;

    if (typeof params.value === "number" && Number.isInteger(params.value))
        displayValue = params.value;
    else if (typeof params.value === "string" && Number.isInteger(Number.parseInt(params.value)) )
        displayValue = Number.parseInt(params.value);
    else 
        displayValue = 0;

    return (
    <Link
        to={`/dashboard/drill-down/${params.row.detailsId.toString()}/${
        ranges
            ? ranges[fields.findIndex((field: { path: string; }) => field.path === params.field) - 1]
            : params?.value?.toString()
        }/${params.row.id.toString()}`}
        style={{
        textDecoration: "none",
        cursor: "pointer",
        marginTop: "auto",
        marginBottom: "auto",
        lineHeight: "initial",
        }}
    >
        <SingleValueDonutChart
        metric={displayValue}
        width={80}
        height={80}
        colorRule={(value: number) => {
            let valueColor = "#008000";
            const maxAcceptableValue = params.row.acceptable;
            const maxManageableValue = params.row.maxMng;
            if (value > maxManageableValue) {
            valueColor = "rgb(243, 67, 54)";
            } else if (value > maxAcceptableValue) {
            valueColor = "orange";
            }
            return valueColor;
        }}
        />
    </Link>
    );    
}

const NumberColumn = (params: GridRenderCellParams, ranges: string[], fields: Fields[]) => {

    // Invalid data
    if (typeof params.value !== "string")
        return <>{params.value}</>;

    // numeric metric (possibly with acceptability override)
    const [value, acceptability] = (params.value as string).split(";");
    let valueAsNumber = Number.parseFloat(value);

    // do not display if the metric value could not be parsed
    if (Number.isNaN(valueAsNumber)) {
        valueAsNumber = 0;
    }
    return (
        <Link
        to={`/dashboard/drill-down/${params.row.detailsId.toString()}/${
            ranges
            ? ranges[fields.findIndex((field: { path: string; }) => field.path === params.field) - 1]
            : params?.value?.toString()
        }/${params.row.id.toString()}`}
        style={{ textDecoration: "none" }}
        >
        <div
            style={{
            height: "100%",
            width: "100%",
            cursor: "pointer",
            }}
        >
            <AnalyticValue
            value={valueAsNumber}
            acceptabilityOverride={
                acceptability as
                | "acceptable"
                | "not-acceptable"
                | "not-manageable"
                | undefined
            }
            maxAcceptable={params.row.acceptable}
            maxManageable={params.row.maxMng}
            />
        </div>
        </Link>
    );
}

  
function DashboardPage() {

    const { range } = useParams() as { range: string; };    

    const theme = useTheme();
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const { pathname } = useLocation();
    const isCustom = matchPath("/dashboard/custom/", pathname) || range;

    const [useCustomPeriod, setUseCustomPeriod] = useState(!!isCustom);
    const [commonFormats,  setCommonFormats] = useState(
        { 
            loading: true, 
            reportingPeriods: [] as CommonFormat[]
        }
    );
    const [serverTz, setServerTz] = useState<string>("")

    const locale = locales[intl.locale] || en;
    const dateFormat = dateFormats[intl.locale.toLowerCase()] || dateFormats['en']
    const dateFormatYY = dateFormatsYY[intl.locale.toLowerCase()] || dateFormatsYY['en']
    const dayMonthFormat = dayMonthFormats[intl.locale.toLowerCase()] || dayMonthFormats['en']

    const [period, setPeriod] = useState({} as PeriodData);
    const [columns, setColumns] = useState([] as GridColDef[]);
    const [rows, setRows] = useState([] as any[]);


    // Initial loading of page information
    useEffect(() => {
        GetAlarmHealthFormats().then(result => {
            if (result.data.serverTz !== serverTz) {
                setServerTz(result.data.serverTz);
            }
            setCommonFormats((prev) => {
                prev.loading = false;
                prev.reportingPeriods = result.data.reportingPeriods;
                return {...prev};
            });
            if (result.data.reportingPeriods?.length) {
                const params = result.data.reportingPeriods[0];
                setPeriod((prev: PeriodData) => {
                    prev.granularity = params.kind.charAt(0);
                    prev.numberOfPreviousPeriods = params.previousPeriods
                    return {...prev};
                });
            }
          })
          .catch(err => {
            console.log(err);
            enqueueSnackbar(intl.formatMessage({id: "dashboard.problemLoadingDashboard"}));
          })      
    }, []);

    // On change of the period
    useEffect(() => {
        if (!period.granularity || period.numberOfPreviousPeriods == null)
            return;
        GetAlarmSummaryFields(period.granularity, period.numberOfPreviousPeriods, GetDateRange(period)).then(result => {
            setColumns(
                result.data.fields.map(
                  (column: { path: string; name: string; datePeriod?: DatePeriod, flexGrow: number }) => {
                    return {
                      field: column.path,
                      headerName: column.datePeriod ? formatHeaderName(column.name, column.datePeriod, serverTz) : column.name,
                      flex: column.flexGrow,
                      resizable: true,
                      sortable: column.path === "label",
                      filterable: column.path === "label",
                      align: column.path === "label" ? "left": "right",
                      headerAlign: column.path === "label" ? "left": "right",
                      filterOperators: column.path === "label" ? getExtendedStringFilterOptions(intl) : getGridStringOperators(),
                      renderCell: (params: GridRenderCellParams) => {
                        if (params.field === "label") {
                          return LabelColumn(params);
                        }
                        switch (params.row.dtype) {
                            case "pie": return PieColumn(params);
                            case "pct-circle": return PctCircleColumn(params, result.data.ranges, result.data.fields);
                            default: return NumberColumn(params, result.data.ranges, result.data.fields);
                        }
                      }
                    } as GridColDef;
                  }
                )
            );
            GetAlarmSummary(result.data.ranges.join(",")).then(summary => {
                setRows(
                    summary.data.data.map(
                      (rowDef: {
                        detailsId: string;
                        detailsHeader: string;
                        label: string;
                        id: string;
                      }) => {
                        return {
                          ...rowDef,
                          tooltip: intl.messages[`metric.${rowDef.id}.tooltip`]
                            ? intl.formatMessage({id: `metric.${rowDef.id}.tooltip`, })
                            : rowDef.label,
                          label: intl.messages[`metric.${rowDef.id}.label`]
                            ? intl.formatMessage({ id: `metric.${rowDef.id}.label`, })
                            : rowDef.label,
                        };
                      }
                    )
                  );
            })
            .catch(err => {
                console.log(err);
                enqueueSnackbar(intl.formatMessage({id: "dashboard.problemLoadingDashboard"}));
            })      
        })
        .catch(err => {
            console.log(err);
            enqueueSnackbar(intl.formatMessage({id: "dashboard.problemLoadingDashboard"}));
        })      
    }, [period]);

    const formatHeaderName = (name: string, datePeriod: DatePeriod, serverTz: string) => {

        if (datePeriod.kind === "quarter")
            return name; // no i18n formatting needed for quarters (example: "Q4 2023")

        const from = dayjs(datePeriod.from).tz(serverTz);
        const to = dayjs(datePeriod.to).tz(serverTz);

        switch (datePeriod.kind) {
            case "month":
                return from.format("MMM-YY");

            case 'day':
                return from.format(dateFormatYY);

            case 'dateRange':
            case 'week': { 
                const fromDt = from.toDate();
                const toDt = to.toDate();
                if (fromDt.getFullYear() !== toDt.getFullYear()) {
                    // starts in one year and ends in another, need to format with both years:
                    // "26 Dec 22 - 1 Jan 23" (with properly reversed month and date for US)
                    return from.format(dateFormatYY) + " - " + to.format(dateFormatYY);
                }
                else if (fromDt.getMonth() !== toDt.getMonth()) {
                    // starts in one month and ends in another one, within the same year,
                    // format as "30 Oct - 5 Nov 23" (with properly reversed month and date for US)
                    return from.format(dayMonthFormat) + " - " + to.format(dateFormatYY);
                }
                else if (fromDt.getDate() !== toDt.getDate()) {
                    // same month, format as "20-26 Nov" (or reversed "Nov 20-26" for US)
                    if (dayMonthFormat?.startsWith("D")) {
                        // non-US format: day first, month after
                        return fromDt.getDate() + "-" + to.format(dateFormatYY);
                    } else {
                        // US format: month first
                        return from.format(dayMonthFormat) + "-" + toDt.getDate() + ", " + to.format("YY");
                    }
                }
                else {
                    // from == to, so just one day
                    return from.format(dateFormatYY);
                } 
            }
            default: // shouldn't get there
                return name;
        }
    };

    const periodOnChange = (event: SelectChangeEvent<unknown> ) => {
        if (!event.target.value || typeof event.target.value !== "string")
            return;
        setUseCustomPeriod(event.target.value === "custom");
        if (event.target.value !== "custom") {
            // parse "1w+3" to ["1w+3", "w", "3"]
            const parsed = event.target.value.match(/\d*([mdwq])\+(\d+)/) || [];
            if (parsed.length === 3) {
                setPeriod((prev: PeriodData) => {
                    prev.granularity = parsed[1] as granularityType;
                    prev.numberOfPreviousPeriods = parseInt(parsed[2]);
                    return {...prev};
                });
            }
        } else {
            // if user selected "Custom" period,
            // by default (initially) pre-select an interval [1st day of current month .. today]
            if (!period.startDate || !period.endDate) {
                setPeriod((prev: PeriodData) => {
                    prev.startDate = dayjs(new Date()).startOf("month").toDate();
                    prev.endDate = dayjs(new Date()).endOf("day").toDate();
                    return {...prev};
                });
            }
        }
    };
    
    const DateRangeConfigControl = {
        marginTop: 0,
        marginLeft: 0,
        marginBottom: theme.spacing(3),
        marginRight: theme.spacing(4),
        minWidth: "12em",
        fontSize: 14,
      }

    return (
          commonFormats.loading ? (
            <PageLoadingIndicator />
          ) : (
            <>
                <Typography variant="h3" style={{ padding: "0.5em 0", flex: 0 }}>
                    <FormattedMessage id={"dashboard.alarmKPIDashboard"} />
                </Typography>

                <form style={{ flex: 0 }}>
                  <FormControl sx={DateRangeConfigControl} variant="standard">
                    <InputLabel id={"time-period-select-label"}>
                        <FormattedMessage id={"dashboard.selectPeriod"} />
                    </InputLabel>
                    <Select
                        labelId={"time-period-select-label"}
                        label={intl.formatMessage({ id: "dashboard.selectPeriod" })}
                        id={"time-period-select"}
                        defaultValue={() => {
                        if (useCustomPeriod || commonFormats.reportingPeriods.length < 1) {
                            return "custom";
                        //} else if (currentFormat) {
                        //    return currentFormat;
                        } else {
                            return `${
                            commonFormats.reportingPeriods[0].num
                            }${commonFormats.reportingPeriods[0].kind.substring(0, 1)}+${
                            commonFormats.reportingPeriods[0].previousPeriods || 0
                            }`;
                        }
                        }}
                        onChange={periodOnChange}
                    >
                        {commonFormats ? (
                        commonFormats.reportingPeriods.map((format, i) => {
                          let translationId = "dashboard.commonFormatTemplate.noPreviousPeriods";

                          if (format.previousPeriods && format.previousPeriods > 1) {
                            translationId = "dashboard.commonFormatTemplate.plural";
                          } else if (format.previousPeriods && format.previousPeriods === 1 ) {
                            translationId = "dashboard.commonFormatTemplate.singular";
                          }
                            return (
                            <MenuItem
                                key={i}
                                value={`${format.num}${format.kind.substring(0, 1)}+${
                                format.previousPeriods || 0
                                }`}
                            >
                                <FormattedMessage
                                id={translationId}
                                values={
                                    format.previousPeriods && format.previousPeriods > 0
                                    ? {
                                        previousPeriods: format.previousPeriods,
                                        duration: format.kind,
                                        }
                                    : { duration: format.kind }
                                }
                                />
                            </MenuItem>
                            );
                        })
                        ) : (
                        <MenuItem value={"1m+3"}>
                            <FormattedMessage
                            id={"dashboard.currentAndPrevThreeMonths"}
                            />
                        </MenuItem>
                        )}
                        <MenuItem value={"custom"}>
                          <FormattedMessage id={"dashboard.customDateRange"} />
                        </MenuItem>
                    </Select>
                    </FormControl>
                    {
                    useCustomPeriod ? (
                        <>
                        <FormControl
                          variant="standard"
                          sx={DateRangeConfigControl}
                          style={{ maxWidth: "fit-content", minWidth: "unset" }}
                        >
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                          <DateRangePicker
                            calendars={1}
                            inputFormat={dateFormat}
                            value={[dayjs(period.startDate).format(dateFormat), dayjs(period.endDate).format(dateFormat)]}
                            onChange={(date: DateRange<Date>) => {
                                if (date[0] && date[1]) {
                                    setPeriod((prev: PeriodData) => {
                                        prev.startDate = date[0];
                                        prev.endDate = dayjs(date[1]).endOf("day").toDate();
                                        return {...prev};
                                    });
                                }
                            }}
                            renderInput={(startProps: any, endProps: any) => (
                                <React.Fragment>
                                    <TextField variant="standard" {...startProps} />
                                    <Box sx={{ mx: 2 }}> to </Box>
                                    <TextField variant="standard" {...endProps} />
                                </React.Fragment>
                                )}
                          />
                        </LocalizationProvider>
                        </FormControl>
                        <Typography
                            sx={DateRangeConfigControl}
                        >
                            <FormattedMessage id={"dashboard.compareWithPrevious"} />
                        </Typography>
                        <FormControl sx={DateRangeConfigControl} variant="standard">
                        <InputLabel id={"time-intervals-select-label"}>
                            {intl.formatMessage({id: "dashboard.selectNumberOfIntervals"})}
                        </InputLabel>
                        <Select
                            labelId={"time-intervals-select-label"}
                            label={intl.formatMessage({id: "dashboard.selectNumberOfIntervals"})}
                            id={"time-intervals-select"}
                            defaultValue={period.numberOfPreviousPeriods}
                            style={{ minWidth: "6em" }}
                            onChange={(event: SelectChangeEvent<unknown>) => {
                                setPeriod((prev: PeriodData) => { prev.numberOfPreviousPeriods = event.target.value as number; return {...prev};});
                            }}
                        >
                            <MenuItem value={"none"}>
                            <FormattedMessage id={"dashboard.none"} />
                            </MenuItem>
                            {[...Array(12)].map((e, i) => {
                            return <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>;
                            })}
                        </Select>
                        </FormControl>
                        <FormControl sx={DateRangeConfigControl} variant="standard">
                        <InputLabel id={"time-granularity-select-label"}>
                            {intl.formatMessage({id: "dashboard.selectGranularity"})}
                        </InputLabel>
                        <Select
                            labelId={"time-granularity-select-label"}
                            label={intl.formatMessage({id: "dashboard.selectGranularity"})}
                            id={"time-granularity-select"}
                            defaultValue={period.granularity}
                            onChange={(event: SelectChangeEvent<unknown>) => {
                                setPeriod((prev: PeriodData) => { 
                                    prev.granularity = event.target.value as granularityType 
                                    return {...prev};
                                });
                            }}
                        >
                            {granularityItems.map((granularity) => (
                            <MenuItem key={granularity} value={granularity}>
                                <FormattedMessage id={"dashboard.".concat(granularity)} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                        </>
                        ) : null
                    }
                </form>

                <DataGridPro
                  sx={{
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                      outline: "none !important",
                    },
                  }} 
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  rows={rows} 
                  columns={columns}
                  rowHeight={100}
                  hideFooter={true}
                  disableSelectionOnClick
                  disableDensitySelector
                  disableColumnReorder
                  autoHeight
                />

            </>
          )

    );
}
  
export default DashboardPage;    