import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  IconButton,
  Container,
  FormControl,
  LinearProgress,
  Typography,
  useTheme,
  TextField,
  Box,
} from "@mui/material";
import { Alert } from "@mui/material";
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro';
import { PageLoadingIndicator } from "catapult-shared-ui-components";

// Helpers
import axios from "axios";
import dayjs from "dayjs";

//Hooks
import { FormattedMessage, useIntl } from "react-intl";
import { useSnackbar } from "notistack"

//Icons
import CloseIcon from '@mui/icons-material/Close';


interface BackfillInfo {
  startDate: Date | null;
  endDate: Date | null;
  // Source ?  
}

function BackfillPage() {
  
  const intl = useIntl();
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const dateFormat = "D MMM YYYY";

  const [isLoading] = useState(false);
  const [initialStatusChecked, setInitialStatusChecked] = useState(false);
  const [showErrorLoading] = useState(false);

  const initialStartDate = dayjs().subtract(8, "days").endOf("day").toDate();
  const initialEndDate = dayjs().endOf("day").toDate();
  const [period, setPeriod] = useState<BackfillInfo>({ 
      startDate: initialStartDate, 
      endDate: initialEndDate, 
  });

//  const [startDate, setStartDate] = useState<Date | null>();
//  const [endDate, setEndDate] = useState<Date | null>();
  const [numEventsReceived, setNumEventsReceived] = useState(0);
  const [lastEventReceivedDate, setLastEventRecievedDate] = useState<
    Date | undefined
  >();
  const [backfillStatus, setBackfillStatus] = useState<undefined | string>();
  const [backfillMessage, setBackfillMessage] = useState<undefined | string>();
  const [backfillStatusCode, setBackfillStatusCode] = useState<
    undefined | number
  >();
  const [backfillFinished, setBackfillFinished] = useState(false);
  const [isBackfilling, setIsBackfilling] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);

  const getAlertType = (code: number) => {
    switch (code) {
      case 0:
      case 6:
        return "success";
      case 4:
        return "warning";
      default:
        return "error";
    }
  };

  const requestBackfill = useCallback(() => {
    setBackfillStatus("backfill.inProgress");
    setProgressPercentage(0);
    setNumEventsReceived(0);
    setBackfillStatusCode(undefined);
    setLastEventRecievedDate(undefined);
    setBackfillFinished(false);
    setIsBackfilling(true);
    axios
      .get("backfillEvents", {
        params: {
          startDate: dayjs(period.startDate).startOf("day").toISOString(),
          endDate: dayjs(period.endDate).endOf("day").toISOString(),
        },
      })
      .then(() => {
        const requestBackfillStatus = window.setInterval(() => {
          axios.get("backfillStatus").then((res) => {
            setBackfillMessage(res.data.status.message);
            setBackfillStatusCode(res.data.status.code);
            setProgressPercentage(res.data.status.progress.pct);
            setNumEventsReceived(res.data.status.progress.count);
            const timestamp = res.data.status.progress.ts;
            setLastEventRecievedDate(
              timestamp ? dayjs.unix(timestamp).toDate() : undefined
            );
            if (res.data.status.progress.pct === 100) {
              clearInterval(requestBackfillStatus);
              setIsBackfilling(false);
              setBackfillStatus("backfill.complete");
              setBackfillFinished(true);
            }
          });
        }, 1000);
      })
      .catch(() => {
        enqueueSnackbar(intl.formatMessage({ id: "backfill.problemBackfilling"}), { 
          variant: 'error',
          action: key => (
            <IconButton
            onClick={() => {
              closeSnackbar(key)
            }
            }
            >
            <CloseIcon />
            </IconButton>
          ),
        });
      setIsBackfilling(false);
    });
    }, [period]);

  const checkForExistingBackfillInProgress = useCallback(() => {
    let completedChecksCount = 0;
    const requestBackfillStatus = window.setInterval(() => {
      axios.get("backfillStatus").then((res) => {
        if (res.data.status.progress.pct !== 100) {
          setPeriod((prev: BackfillInfo) => {
            prev.startDate = dayjs.unix(res.data.status.progress.from).toDate();
            prev.endDate = dayjs.unix(res.data.status.progress.to).toDate();
            return {...prev};
          });          

          setBackfillStatus("backfill.inProgress");
          setBackfillFinished(false);
          setIsBackfilling(true);
          setBackfillMessage(res.data.status.message);
          setBackfillStatusCode(res.data.status.code);
          setProgressPercentage(res.data.status.progress.pct);
          setNumEventsReceived(res.data.status.progress.count);
          const timestamp = res.data.status.progress.ts;
          setLastEventRecievedDate(
            timestamp ? dayjs.unix(timestamp).toDate() : undefined
          );
        } else {
          setIsBackfilling(false);
          clearInterval(requestBackfillStatus);
          if (completedChecksCount > 0) {
            setBackfillFinished(true);
            setBackfillMessage(res.data.status.message);
            setBackfillStatusCode(res.data.status.code);
            setProgressPercentage(res.data.status.progress.pct);
            setNumEventsReceived(res.data.status.progress.count);
            const timestamp = res.data.status.progress.ts;
            setLastEventRecievedDate(
              timestamp ? dayjs.unix(timestamp).toDate() : undefined
            );
            setBackfillStatus("backfill.complete");
          }
        }
        setInitialStatusChecked(true);
        completedChecksCount++;
      });
    }, 1000);
  }, []);


  useEffect(() => {
    checkForExistingBackfillInProgress();
  }, [checkForExistingBackfillInProgress]);

  const DateRangeConfigControl = {
    marginTop: 0,
    marginLeft: 0,
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(4),
    minWidth: "12em",
    fontSize: 14,
  }

  return (
    <Container sx={{
      width: "100%",
      height: "100%",
    }}>
      {(isLoading || !initialStatusChecked) && <PageLoadingIndicator />}
      {!isLoading && initialStatusChecked && !showErrorLoading && (
        <div>
          <Typography
            variant="h3"
            style={{ padding: "0.5em 0 0.5em 0", flex: 0 }}
          >
            <FormattedMessage id="backfill.backfillData" />
          </Typography>
          <FormControl
              variant="standard"
              sx={DateRangeConfigControl}
              style={{ maxWidth: "fit-content", minWidth: "unset" }}
            >
            <DateRangePicker
                calendars={2}
                disableFuture
                inputFormat={dateFormat}
                value={[dayjs(period.startDate).format(dateFormat), dayjs(period.endDate).format(dateFormat)]}
                onChange={(date: DateRange<Date>) => {
                    setPeriod((prev: BackfillInfo) => {
                        prev.startDate = date[0];
                        prev.endDate = date[1]; 
                        return {...prev};
                    });
                }}
                renderInput={(startProps: any, endProps: any) => (
                    <React.Fragment>
                        <TextField variant="standard" {...startProps} />
                        <Box sx={{ mx: 2 }}> to </Box>
                        <TextField variant="standard" {...endProps} />
                    </React.Fragment>
                    )}
            />
            </FormControl>          
          <FormControl style={{ top: theme.spacing(1.5) }}>
            {initialStatusChecked && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => requestBackfill()}
                disabled={!period.startDate || !period.endDate || isBackfilling}
              >
                <FormattedMessage id="backfill.requestBackfill" />
              </Button>
            )}
          </FormControl>
        </div>
      )}
      {(isBackfilling || backfillFinished) && initialStatusChecked && (
        <div>
          <Alert
            severity={getAlertType(backfillStatusCode || 0)}
            style={{
              marginBottom: theme.spacing(1),
              visibility:
                (backfillStatusCode && !backfillFinished) ||
                (backfillStatusCode === 0 && backfillFinished)
                  ? "visible"
                  : "hidden",
            }}
          >
            <FormattedMessage
              id="backfill.statusAlert"
              values={{
                message: backfillMessage,
                code: backfillStatusCode,
              }}
            />
          </Alert>
          {backfillStatus && (
            <Typography variant="h6">
              <FormattedMessage id={backfillStatus} />
            </Typography>
          )}
          {!backfillFinished && isBackfilling && (
            <LinearProgress
              variant="buffer"
              value={progressPercentage}
              valueBuffer={100}
              style={{ marginBottom: theme.spacing(1) }}
            />
          )}
          <Typography style={{ visibility: period.startDate ? "visible" : "hidden" }}>
            <FormattedMessage
              id={"backfill.from"}
              values={{ date: period.startDate ? intl.formatDate(period.startDate, {day: "numeric", month: "long", year: "numeric"}) : null }}
            />
          </Typography>
          <Typography style={{ visibility: period.endDate ? "visible" : "hidden" }}>
            <FormattedMessage
              id={"backfill.to"}
              values={{ date: period.endDate ? intl.formatDate(period.endDate, {day: "numeric", month: "long", year: "numeric"}) : null }}
            />
          </Typography>
          <Typography
            style={{
              visibility:
                isBackfilling || backfillFinished ? "visible" : "hidden",
            }}
          >
            <FormattedMessage
              id={"backfill.eventsReceived"}
              values={{ count: numEventsReceived }}
            />
          </Typography>
          <Typography>
            {lastEventReceivedDate && (
              <FormattedMessage
                id={"backfill.latestEvent"}
                values={{ event: intl.formatDate(lastEventReceivedDate) }}
              />
            )}
          </Typography>
        </div>
      )}

    </Container>
  );
}

export default BackfillPage;