import React, { useState } from "react";
import { useNavigate } from "react-router";

import { 
    Container,
    Box, 
    Typography,
    Button,
    Grid,
    TextField 
} from "@mui/material";

import {useTheme}  from '@mui/material';
import {useAuth}  from '../hooks/useAuth';
import { FormattedMessage, useIntl } from "react-intl";
import {SendLogin} from "../service/ApiService"


interface LoginPageProps {
    children?: React.ReactNode;
}
  
function LoginPage(props: LoginPageProps) {

    const { children, ...other } = props;

    const auth = useAuth();
    const theme = useTheme();
    const intl = useIntl();
    const navigate = useNavigate();

    const [loginValues, setLoginValues] = useState({username: "", password: ""});

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setLoginValues({
            ...loginValues,
            [name]: value,
        });
    };

    const handleSubmit = (event: React.ChangeEvent<{}>) => {
        event.preventDefault();
        SendLogin(loginValues.username, loginValues.password)
        .then((res) => {
            auth.SetLoginInfo(res.data);
            console.log("logged in");
            navigate("/dashboard", { replace: true });
        })
        .catch((err) => {
            auth.SetLoginInfo({});
        });
    };
 
    return (
        <Container>
        { auth.HasAuthority(null) ? null : 
            <>
                <Typography variant="h3" style={{padding: "0.5em 0"}}><FormattedMessage id={'login.title'}/></Typography>
                <Box
                    component='form'
                    noValidate
                    autoComplete='off'
                    onSubmit={handleSubmit}
                >            
                    <Grid>
                        <Grid item>
                            <TextField
                                id="username"
                                name="username"
                                label={intl.formatMessage({ id: "users.username" })}
                                type="text"
                                value={loginValues.username || "" }
                                onChange={handleInputChange}
                                sx={{ mb: theme.spacing(1) }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="password"
                                name="password"
                                label={intl.formatMessage({ id: "users.password" })}
                                type="password"
                                value={loginValues.password || ""} 
                                onChange={handleInputChange}
                                sx={{ mb: theme.spacing(1) }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                type="submit"
                                fullWidth
                            >
                                {intl.formatMessage({ id: "signIn" })}
                            </Button>                    
                        </Grid>
                    </Grid>
                </Box>
            </>
        }
        </Container>
    );
}
  
export default LoginPage;    