import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

//Icons
import CheckIcon from '@mui/icons-material/Check';
import FlagIcon from '@mui/icons-material/Flag';
import NotificationsIcon from '@mui/icons-material/Notifications';

import React, { CSSProperties } from 'react';
import { useIntl } from 'react-intl';

export interface AnalyticValueProps {
  value: number,
  acceptabilityOverride?: 'acceptable' | 'not-acceptable' | 'not-manageable',
  maxAcceptable: number,
  maxManageable: number,
  style?: CSSProperties,
  className?: string,
}

export function AnalyticValue({value, maxAcceptable, maxManageable, acceptabilityOverride }: AnalyticValueProps) {

  const intl = useIntl();
  const warningStyles = {
    ok: {
      color: "success",
      class: {color: "success.main"},
      icon: <CheckIcon sx={{color: "success.main"}}/>
    },
    warning: {
      color: "warning",
      class: {color: "warning.main"},
      icon: <FlagIcon sx={{color: "warning.main"}}/>
    },
    severeWarning: {
      color: "error",
      class: {color: "error.main"},
      icon: <NotificationsIcon sx={{color: "error.main"}}/>
    }
  }
  let warningStyle = warningStyles.ok;
  if (acceptabilityOverride === 'not-manageable' || (!acceptabilityOverride && value > maxManageable)) {
    warningStyle = warningStyles.severeWarning
  } else if (acceptabilityOverride === 'not-acceptable' || (!acceptabilityOverride && value > maxAcceptable)) {
    warningStyle = warningStyles.warning
  }
  return (
    <Box sx={{display: 'inline-flex', flexDirection: 'row'}}>
      <Typography sx={warningStyle.class} style={{fontSize: "1.5em", lineHeight: "1.25"}}>
        {intl.formatNumber(value)}
      </Typography>
      {warningStyle.icon}
    </Box>
  )
}
