import React, { useEffect, useState } from "react";

// hooks
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack"
import { FormattedMessage, useIntl } from "react-intl";

import { ThemeProvider as ChartThemeProvider } from "@visx/xychart";

import {Divider, Typography, useTheme}  from '@mui/material';
import {
  getXYChartTheme, 
  TimeSeriesChart, 
  TimeSeriesDatum,
} from "catapult-shared-ui-components";
import { ParentSize } from "@visx/responsive";
import {GetTimeSeriesData} from "../service/ApiService"
import dayjs from "dayjs";



function DrillDownTimeSeries() {
    const theme = useTheme();
    const { dataset, range, metric } = useParams() as {
      dataset: string;
      range: string;
      metric: string;
    };
  
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const [timeSeriesData, setTimeSeriesData] = useState<TimeSeriesDatum[]>([]);
    

    const convertMetricForTimeSeriesRequest = (metric: string) => {
      switch (metric) {
        case "avg_alarm_rate_per_day":
          return "alarms_per_day";
        case "avg_alarm_rate_per_hour":
          return "alarms_per_hour";
        case "avg_alarm_rate_per_10min":
        case "max_alarm_per_10min_intvl":
          return "alarms_per_10min";
        default:
          return undefined;
      }
    };
    const getChartTitleForMetric = (metric: string | undefined) => {
      switch (metric) {
        case "avg_alarm_rate_per_day":
          return "timeseries.alarms_per_day.header";
        case "avg_alarm_rate_per_hour":
          return "timeseries.alarms_per_hour.header";
        case "avg_alarm_rate_per_10min":
        case "max_alarm_per_10min_intvl":
          return "timeseries.alarms_per_10min.header";
        default:
          return "";
      }
    };
  
    // Initial loading of time series
    useEffect(() => {
      const convertedMetric = convertMetricForTimeSeriesRequest(metric);
      if (convertedMetric)
        GetTimeSeriesData(convertedMetric, range).then(result => {
          setTimeSeriesData(
            result.data.data[convertedMetric].map(
              ({ ts, y0 }: { ts: number; y0: string }) => {
                return {
                  x: dayjs(ts).toDate(),
                  y: Number.parseInt(y0),
                  seriesKey: convertedMetric,
                };
              }
            )
          );          
        })
        .catch(() => {
            enqueueSnackbar(intl.formatMessage({id: "dashboard.problemLoadingDashboard"}));
        })      
    }, []);

    if (getChartTitleForMetric(metric) )
    return (
      <ChartThemeProvider theme={getXYChartTheme(theme)}>
            <div
              style={{
                flexDirection: "column",
                flex: 0,
                marginBottom: theme.spacing(2),
              }}
            >
              <Typography variant="h3" style={{ padding: "0.5em 0 0 0" }}>
                <FormattedMessage id={getChartTitleForMetric(metric)} />
              </Typography>
              <div style={{ height: "35rem", maxHeight: "60vh" }}>
                <ParentSize>
                  {({ width: visWidth, height: visHeight }) => (
                    <TimeSeriesChart
                      width={visWidth}
                      height={visHeight}
                      data={timeSeriesData}
                      variableNameKey={dataset}
                      useGranularTooltip={dataset !== "top_days"}
                    />
                  )}
                </ParentSize>
              </div>
              <Divider />
            </div>
          </ChartThemeProvider>
    )
    else return (<></>) // Return nothing no time series available
}

export default DrillDownTimeSeries;
    