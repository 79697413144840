import React, { useEffect, useState } from "react";

import { 
  Box,
  TextField,
  Typography
} from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridToolbar,
} from '@mui/x-data-grid-pro'
import DrillDownTimeSeries from "../components/DrillDownTimeSeries";

// hooks
import {useTheme}  from '@mui/material';
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack"
import {   
  FormattedMessage,
  useIntl,
} from "react-intl";

// APi service
import {GetDrillDownData, GetAlarmHealthFormats} from "../service/ApiService"

// Helpers
import latinize from "latinize";
import { FieldFactory } from "../helpers/GridFieldFactory";


function DrillDown() {
    const theme = useTheme();
    const { dataset, range, tagName } = useParams() as {
      dataset: string;
      range: string;
      tagName: string;
    };
  
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const [rows, setRows] = useState([] as any[]);
    const [columns, setColumns] = useState([] as GridColDef[]);
    const [filter, setFilter] = useState<string>();


    const DefineColumns = (serverTz: string) => {
      void serverTz;
      const fields: GridColDef[] = [];

      if (dataset === "most_frequent_alarms") {
        fields.push(FieldFactory("tagName", dataset, intl));
        fields.push(FieldFactory("alarmText", dataset, intl));
        fields.push(FieldFactory("areas", dataset, intl));
        fields.push(FieldFactory("extText1", dataset, intl));
        fields.push(FieldFactory("extText2", dataset, intl));
        fields.push(FieldFactory("count", dataset, intl));
        fields.push(FieldFactory("range", dataset, intl));
      }
      
      else if (dataset === "alarm_floods") {
        fields.push(FieldFactory("start", dataset, intl));
        fields.push(FieldFactory("end", dataset, intl));
        fields.push(FieldFactory("count", dataset, intl));
        fields.push(FieldFactory("duration", dataset, intl));
        fields.push(FieldFactory("peakRate", dataset, intl));
      
      } else if (dataset === "chattering_alarms" || dataset === "fleeting_alarms") {
        fields.push(FieldFactory("tagName", dataset, intl));
        fields.push(FieldFactory("areas", dataset, intl));
        fields.push(FieldFactory("extText1", dataset, intl));
        fields.push(FieldFactory("extText2", dataset, intl));
        fields.push(FieldFactory("earliestOccasion", dataset, intl));
        fields.push(FieldFactory("latestOccasion", dataset, intl));
        fields.push(FieldFactory("totalDuration", dataset, intl));
        if (dataset === "chattering_alarms")
          fields.push(FieldFactory("totalTransitions", dataset, intl));
        fields.push(FieldFactory("range", dataset, intl, "", {width: 175 }));
      
      } else if (dataset === "chattering_alarms_for_tag" || dataset === "fleeting_alarms_for_tag" || dataset === "stale_alarms") {
        if (dataset === "stale_alarms")
          fields.push(FieldFactory("tagName", dataset, intl, "", { size: 1 }));
        else
          fields.push(FieldFactory("tagName", dataset, intl, ""));
        fields.push(FieldFactory("start", dataset, intl));
        fields.push(FieldFactory("end", dataset, intl));
        fields.push(FieldFactory("alarmText", dataset, intl));
        fields.push(FieldFactory("areas", dataset, intl));
        fields.push(FieldFactory("extText1", dataset, intl));
        fields.push(FieldFactory("extText2", dataset, intl));
        fields.push(FieldFactory("duration", dataset, intl));
        fields.push(FieldFactory("closeType", dataset, intl));
        if (dataset === "chattering_alarms_for_tag")
          fields.push(FieldFactory("transitionsCount", dataset, intl));
        
        if (dataset === "chattering_alarms_for_tag" || dataset === "fleeting_alarms_for_tag") {
          fields.push(FieldFactory("range", dataset, intl));
        }

      } else if (dataset === "top_days") {
        fields.push(FieldFactory("from", dataset, intl));
        fields.push(FieldFactory("value", dataset, intl));
        fields.push(FieldFactory("range", dataset, intl));
      }
      else {
        fields.push(FieldFactory("date", dataset, intl));
        fields.push(FieldFactory("timeFrom", dataset, intl));
        fields.push(FieldFactory("timeTo", dataset, intl));
        fields.push(FieldFactory("value", dataset, intl));
        fields.push(FieldFactory("range", dataset, intl));

      }
      return fields;
    }

    // Initial loading of time series
    useEffect(() => {
      GetAlarmHealthFormats().then(formats => {
        GetDrillDownData(dataset, range, tagName).then(result => {
          const fields = DefineColumns(formats?.data?.serverTz);
          setColumns(fields);
          if (dataset in result.data.data) {
            const includeRangeCol = fields.find(
              (col) => col.field === "range"
            );
            let rowsWithIds = (result.data.data[dataset] as any[]).map(
              (row, i) => {
                if (includeRangeCol && !row.range)
                  return { ...row, id: i, range: result.data.requestParams?.range };
                else
                  return { ...row, id: i };
              }
            );
            if (result.data.data[dataset].length > 0) {
              // add data for date columns and convert to Date type
              if (result.data.data[dataset][0].from) {
                rowsWithIds = rowsWithIds.map((row) => {
                  return { ...row, date: new Date(row.from) };
                });
              }
              if (result.data.data[dataset][0].start) {
                rowsWithIds = rowsWithIds.map((row) => {
                  return {
                    ...row,
                    start: new Date(row.start),
                    end: row.end && new Date(row.end),
                  };
                });
              }
            }
            setRows(rowsWithIds);
          }
        })
        .catch(() => {
            enqueueSnackbar(intl.formatMessage({id: "dashboard.problemLoadingDashboard"}));
        })      
      })
      .catch(() => {
          enqueueSnackbar(intl.formatMessage({id: "dashboard.problemLoadingDashboard"}));
      })      
  }, [dataset]);

    return (
      <Box sx={{
          width: "100%",
          height: "100%",
          '& .MuiDataGrid-root .MuiDataGrid-row.Mui-odd:hover': {
            backgroundColor: 'revert',
          },
        }}
      >
        <Box
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            marginBottom: theme.spacing(2),
          }}
        >
          <DrillDownTimeSeries />
          <Typography variant="h3" style={{ padding: "0.5em 0", flex: 0 }}>
            <FormattedMessage id={`drilldown.${dataset}.header`} />
          </Typography>
          <TextField
            variant="standard"
            type="search"
            placeholder={intl.formatMessage({ id: "search" })}
            onChange={(e) => {
              setFilter(latinize(e.target.value.toUpperCase()));
            }}
            style={{ marginBottom: theme.spacing(1) }}
          />          
          <DataGridPro
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                outline: "none !important",
              },
            }} 
            components={{
              Toolbar: GridToolbar,
            }}
            rows={
              filter && filter !== ""
                ? rows.filter((row) =>
                  Object.getOwnPropertyNames(row).find((prop) =>
                    latinize(row[prop].toString().toUpperCase()).includes(
                    filter
                  )
                )
              )
              : rows
            }
            columns={columns}
            rowHeight={80}
            pagination={false}
            disableSelectionOnClick
            disableDensitySelector
            disableColumnReorder
            autoHeight
          />
        </Box>
      </Box>
    )
}

export default DrillDown;
    