import {
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material'

import Box from '@mui/material/Box';
import { SelectChangeEvent } from "@mui/material";

//Icons
import EditIcon from '@mui/icons-material/Edit';

import React, { ChangeEvent, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormDialog } from './FormDialog'
import { SaveButton } from './SaveButton'

export interface ConfigFieldProps {
  configIndex: Number
  i18nNameKey: string
  initialValue: Number | String | string[] | boolean
  isReadOnly: boolean
  onChange: Function
  category: string
  inputType?: string
  selectOptions?: string[] // stores values for select/mulitselect options
  defaultOptionsText?: string[] // stores default translations for select/multiselect options
  savePassword?(fieldKey: string, password: string): Promise<void>
}

/**
 * Returns a relative width value between 1 and 12, according to how most grid systems work
 */
export function getInputTypeWidth(inputType: string | undefined) {
  switch (inputType) {
    case 'file': 
      return 6;

    case 'bool':
    case 'password':
    case 'integer':
    case 'string':
    case undefined:
    default:
      return 3;
  }
}

function getInputType(inputType: string | undefined) {
  switch (inputType) {
    case 'bool':
      return //do not return any type because boolean is only represented by switches, which do not need this method.
    case 'password':
      return 'password'
    case 'integer':
      return 'number'
    case 'string':
    case 'file': //not a file uploader because server currently wants path, which cannot be extracted for browser security reasons
    case undefined:
    default:
      return 'string'
  }
}
function getBooleanFromConfig(toConvert: String | Number | string[] | boolean) {
  if (typeof toConvert == 'boolean') {
    return toConvert;
  }
  if (Array.isArray(toConvert) || typeof toConvert === 'undefined') {
    return false;
  }
  if (isNaN(Number.parseInt(toConvert.toString()))) {
    return Boolean(toConvert).valueOf();
  } else {
    return Boolean(Number.parseInt(toConvert.toString())).valueOf();
  }
}

export function ConfigField(props: ConfigFieldProps) {
  const [currentValue, setCurrentValue] = useState(props.inputType === 'multiselect' ? props.initialValue || [] : props.initialValue);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(props.inputType === 'bool' ? e.target.checked : e.target.value);
    props.onChange(
      props.i18nNameKey,
      props.configIndex,
      props.inputType === 'bool' ? e.target.checked : e.target.value,
      props.category
    );
    if (props.inputType === 'password') setNewPasswordToSave(e.target.value);
  }

  const handleChangeMultipleSelect = (e: SelectChangeEvent<unknown>) => {
    const { value } = e.target as HTMLSelectElement;
    setCurrentValue(value);
    props.onChange(
      props.i18nNameKey,
      props.configIndex,
      value,
      props.category
    )
  }
  
  const intl = useIntl()

  const [setFocus, setFocusInput] = useState<HTMLInputElement | null>(null)
  const [openPasswordChangeDialog, setOpenPasswordChangeDialog] = useState(false);
  const [savingNewPassword, setSavingNewPassword] = useState(false);
  const [newPasswordToSave, setNewPasswordToSave] = useState('');
  const [passwordConfirmationValue, setPasswordConfirmationValue] = useState('');
  const executePasswordConfigSave = (e: React.MouseEvent<HTMLElement>, configKey: string, configPass: string) => {
    e.preventDefault();
    setSavingNewPassword(true);
    return props.savePassword && props.savePassword(configKey, configPass).then(() => {
      setSavingNewPassword(false);
    }, () => {
      setSavingNewPassword(false);
    });
  }

  // Handle the focus for a dialog
  useEffect(() => {
    if (setFocus)
      setFocus.focus();
  }, [setFocus]);
    
  switch (props.inputType) {
    case 'bool':
      return (
        <FormControlLabel
          disabled={props.isReadOnly}
          sx={{
            height: '3em',
          }}
          htmlFor={props.i18nNameKey}
          label={intl.formatMessage({ id: props.i18nNameKey })}
          control={
            <Switch
            sx={{ ml: '-11px' }}
              color='primary'
              checked={getBooleanFromConfig(currentValue)}
              id={props.i18nNameKey}
              onChange={onChange}
            />
          }
        />
      )
    case 'select':
    case 'multiselect':
      let isMultiSelect = props.inputType === 'multiselect'
      return (
        <FormControl
          variant="standard" 
          fullWidth
          disabled={props.isReadOnly}
        >
          <InputLabel id={props.i18nNameKey + '-label'}>
            {intl.formatMessage({ id: props.i18nNameKey })}
          </InputLabel>
          <Select
            labelId={props.i18nNameKey + '-label'}
            label={intl.formatMessage({ id: props.i18nNameKey })}
            id={props.i18nNameKey}
            multiple={isMultiSelect}
            value={isMultiSelect ? currentValue || [] : currentValue}
            onChange={isMultiSelect ? handleChangeMultipleSelect : onChange}
            // TODO locale-specific renderer
            renderValue={
              isMultiSelect
                ? (valueToRender: string[]) => {
                    console.log(valueToRender)
                    return (
                      <Typography>
                        {valueToRender
                          .map((element) => intl.formatMessage({ id: element }))
                          .join(', ')}
                      </Typography>
                    )
                  }
                : (valueToRender: string) => {
                    return (
                      <Typography>
                        <FormattedMessage id={valueToRender} />
                      </Typography>
                    )
                  }
            }
          >
            {props.selectOptions?.map((option, idx) => {
              return (
                <MenuItem key={option} value={option}>
                  {intl.messages[option] && <FormattedMessage id={option} />}
                  {!intl.messages[option] && props.defaultOptionsText && (
                    <p style={{ margin: '0' }}>
                      {props.defaultOptionsText[idx]}
                    </p>
                  )}
                  {!intl.messages[option] && !props.defaultOptionsText && (
                    <FormattedMessage id={option} />
                  )}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      )
    case 'password':
      return (
        <Box sx={{
          display: 'inline-block',
          marginBottom: 3,
          marginRight: 4,
          paddingTop: 2,
          height: '3em'
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '16em'
          }}>
            <Typography variant="body1" style={{flexGrow: 0, display: 'inline-block'}}>
              <FormattedMessage id="config.passwordPreview" values={{passwordName: intl.formatMessage({ id: props.i18nNameKey }), password: '•••••'}} />
            </Typography>
            <IconButton
              disabled={props.isReadOnly}
              aria-label="edit"
              onClick={() => setOpenPasswordChangeDialog(true)}
              sx={{
                flexGrow: 0,
                marginTop: -1.5,
                marginLeft: 1,
              }}>
              <EditIcon />
            </IconButton>
          </Box>
          <FormDialog open={openPasswordChangeDialog} openSetter={setOpenPasswordChangeDialog} i18nTitleKey={props.i18nNameKey}
            actionComponents={[
              <SaveButton
                saving={savingNewPassword}
                disabled={newPasswordToSave === '' || passwordConfirmationValue !== newPasswordToSave}
                isFormSubmit={false} //to avoid nested form element antipattern
                onClick={(e) => executePasswordConfigSave(e, props.i18nNameKey, newPasswordToSave)}
              />
            ]}
          >
            <TextField
              inputRef={el => {setFocusInput(el);}}
              sx={{ mt: 1, mr: 0 }}
              error={newPasswordToSave === ''}
              helperText={newPasswordToSave === '' ? intl.formatMessage({ id: 'config.pleaseEnterNewPassword' }) : null}
              id={props.i18nNameKey}
              label={intl.formatMessage({ id: 'config.newPassword' })}
              type={getInputType(props.inputType)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {onChange(e); setNewPasswordToSave(e.target.value)}}
              disabled={props.isReadOnly}
              // This component is for specifying a new password, therefore autofill is disabled.
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
            />
            <TextField
              error={passwordConfirmationValue !== newPasswordToSave}
              helperText={passwordConfirmationValue !== newPasswordToSave ? intl.formatMessage({ id: 'config.passwordsMustMatch' }) : null}
              id={props.i18nNameKey + 'Confirm'}
              label={intl.formatMessage({ id: 'config.confirmNewPassword' })}
              type={getInputType(props.inputType)}
              onChange={(e) => setPasswordConfirmationValue(e.target.value)}
              disabled={props.isReadOnly}
              // This component is for confirming a new password, not logging in, therefore autofill is disabled.
              inputProps={{
                autoComplete: 'confirm-password',
                form: {
                  autoComplete: 'off',
                },
              }}
            />
          </FormDialog>
        </Box>
      );
    case 'integer':
      return (
        <TextField
          id={props.i18nNameKey}
          variant="standard" 
          fullWidth
          label={intl.formatMessage({ id: props.i18nNameKey })}
          type='number'
          defaultValue={currentValue}
          onChange={onChange}
          disabled={props.isReadOnly}
        />
      )

    case 'string':
    case 'file': //not a file uploader because server currently wants path, which cannot be extracted for browser security reasons
    case undefined:
    default:
      return (
        <TextField
          id={props.i18nNameKey}
          variant="standard" 
          fullWidth
          label={intl.formatMessage({ id: props.i18nNameKey })}
          type={getInputType(props.inputType)}
          defaultValue={currentValue}
          onChange={onChange}
          disabled={props.isReadOnly}
        />
      )
  }
}
